@import '../../colorVariables.scss';

.applicants {
	display: flex;
	flex-direction: column;
	min-height: var(--doc-height);
	.applicantsContainer {
		text-align: left;
	}
}
.list-group-item-custom {
	cursor: pointer;
	transition: background-color 0.3s;
	background-color: $background-light-cream;
	margin-bottom: 6px;
	border-radius: 5px;

	&:hover {
		background-color: $background-grey !important;
	}
}
.list-group-item-custom-selected {
	box-shadow: $light-blue 0px 0px 0px 2px;
	background-color: $background-grey !important;
}

.list-group-item-custom:hover {
	background-color: $background-grey; /* Change this color as per your theme */
}

.manage-button {
	cursor: pointer;
	color: $deep-blue;
	&:hover {
		color: $link-blue;
	}
}

.thumb-group {
	border-radius: 5px;
	border: 1.5px solid $background-grey;
}

.thumb {
	cursor: pointer;
	color: $charcoal;
	&:hover {
		background-color: $background-grey;
	}
}

.thumb-1 {
	border-radius: 5px 0 0 5px;
}

.thumb-3 {
	border-radius: 0 5px 5px 0;
}

.thumb-selected {
	background-color: $blue;
	color: $white;
	&:hover {
		background-color: $blue;
	}
}
.thumb-disabled {
	opacity: 0.5;
	pointer-events: none;
}

/* Optional: add a loading cursor */
.loading {
	cursor: wait;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
	display: none;
}

.react-pdf__Page__canvas {
	width: 100% !important;
	height: fit-content !important;
	object-fit: cover;
	border-radius: 5px;
}

.fun-border {
	border: 1px solid $background-grey;
	border-radius: 5px;
}

.tag-app-cream {
	background-color: $background-light-cream;
	color: $dark-charcoal;
	border-radius: 5px;
	padding: 6px 8px;
}

.tag-app-background-grey {
	background-color: $background-greyless;
	color: $dark-charcoal;
	border-radius: 5px;
	padding: 6px 8px;
}

.tag-app-background-blue {
	background-color: $light-blueless;
	color: $dark-charcoal;
	border-radius: 5px;
	padding: 6px 8px;
}

.tag-app-link-blue {
	background-color: $link-blue;
	color: $white;
	border-radius: 5px;
	padding: 6px 8px;
}
