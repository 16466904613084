@import '../../colorVariables.scss';

.googleButton {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.auth-page-container {
	display: flex;
	min-height: var(--doc-height);
	width: 100vw;

	.right-side {
		min-height: 90vh;
		background-image: url('../../../public/subtle-prism.svg');
		background-size: cover;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		text-align: center;
		margin: 20px;
		overflow: hidden;
		color: $white;
	}
	.left-side {
		width: 650px;
		min-height: var(--doc-height);
		max-width: 100%;
		margin-left: 10px;
		margin-right: 10px;
		transition: 0.3s ease-in-out;
		text-align: left;
		display: flex;
		flex-direction: column;
		justify-content: top;
		align-items: center;
		h1 {
			font-size: 1.3em;
			font-weight: 700;
			text-align: left;
			color: $dark-charcoal;
			margin-bottom: 3px;
		}
		h2 {
			font-size: 0.95em;
			font-weight: 500;
			text-align: left;
			color: $charcoal;
		}
		.middleContainer {
			margin: 20px auto;
			width: 100%;
			max-width: 500px;
			padding: 50px;
			box-shadow:
				rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
				rgba(27, 31, 35, 0.1) 0px 0px 0px 1px;

			form {
				width: 100%;
				border-radius: 5px;
				text-align: left;

				img {
					margin: 0;
					margin-right: 15px;
				}
				.text {
					margin-bottom: 40px;

					p {
						margin: 0;
						padding: 0;
					}
				}

				.divider-container {
					width: 100%;
					margin: 40px 0;

					.divider {
						display: flex;
						align-items: center;
						text-align: center;

						hr {
							border: 0;
							border-top: 1px solid;
							flex-grow: 1;
						}

						span {
							margin: 0 20px;
						}
					}
				}
				.google-button {
					display: flex;
					justify-content: center;
				}

				.emailError {
					width: fit-content;
					color: red;
					border: 1px red solid;
					border-radius: 3px;
					padding: 10px;
					margin: 20px auto;
				}
				.topLogo {
					width: 50px;
					object-fit: cover;
					margin-bottom: 30px;
					border-radius: 50%;
					cursor: pointer;
				}
				h2 {
					font-weight: 400;
					margin-top: 0;
					padding-top: 0;
				}
				// input {
				// 	width: 100%;
				// 	margin: 0 auto;
				// 	margin-bottom: 30px;
				// 	padding: 10px 20px;
				// 	border-radius: 5px;
				// 	border: 1px solid #5f6c7b80;
				// 	font-size: 0.9em;
				// }
				select {
					width: 100%;
					margin-bottom: 20px;
					padding: 10px 13px;
				}
				.halfInput {
					display: flex;
					width: 100%;

					input {
						width: 100%;
					}
					.marginRight {
						margin-right: 10px;
					}
				}
				.checkbox {
					width: 100%;
					input {
						width: 15px;
						height: 15px;
						float: left;
						margin-top: 3px;
					}

					p {
						text-align: left;
						line-height: 20px;
						padding-left: 30px;
					}
				}

				// button {
				// 	display: block;
				// 	margin: 0 auto;
				// 	padding: 10px 18px;
				// 	min-height: 0;
				// 	width: 100%;
				// }
				.loader {
					position: fixed;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100vw;
					height: var(--doc-height);
					background-color: #fffffe90;
					.loaderInner {
						position: fixed;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
				.selector {
					width: 100%;
					display: flex;
					justify-content: space-between;
					.p1 {
						border: 1px solid #09406780;
						border-radius: 3px 0 0 3px;
						width: 50%;
						padding: 10px 20px;
						font-size: 16px;
						border-right: 0.5px solid #09406780;
						cursor: pointer;
						&:hover {
							background-color: #09406795;
							color: #fffffe;
						}
					}
					.p2 {
						border: 1px solid #09406780;
						border-left: 0.5px solid #09406780;
						border-radius: 0 3px 3px 0;
						width: 50%;
						padding: 10px 20px;
						font-size: 16px;
						cursor: pointer;
						&:hover {
							background-color: #09406795;
							color: #fffffe;
						}
					}
					.selection {
						background-color: #094067;
						color: #fffffe;
					}
				}
			}
		}
	}
}

.google-button {
	max-width: 100% !important;
}
// create a media query for middle container to change padding to 30px for less than 500px

@media screen and (max-width: 950px) {
	.auth-page-container {
		.right-side {
			display: none;
		}
	}
}

@media screen and (max-width: 720px) {
	.auth-page-container {
		.left-side {
			width: 100%;
		}
	}
}

@media screen and (max-width: 525px) {
	.auth-page-container {
		.left-side {
			.middleContainer {
				padding: 50px 25px;
			}
		}
	}
}
