$white: #fffffe;
$whiteless: #fffffe60;
$navy: #094067;
$navyless: #09406790;
$blue: #3da9fc;
$light-blue: #d8eefe;
$grey: #5f6c7b;
$red: #ef4565;

.confirmEmail {
	display: flex;
	align-items: center;
	min-height: 80vh;
	.confirmCard {
		width: 600px;
		box-shadow: rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
		border: 1px solid #d8eefe;
		max-width: 95vw;
		margin: 20px auto;
		padding: 50px;
		border-radius: 10px;
		text-align: left;

		display: flex;
		flex-direction: column;
		gap: 15px;
		h2 {
			font-weight: 500;
			margin-top: 0;
			padding-top: 0;
		}
		p,
		h5 {
			margin: 0;
			padding: 0;
			font-weight: 400;
			font-size: 1em;
		}

		.cardText {
			display: flex;
			flex-direction: column;
			gap: 15px;

			p {
				margin: 0;
				padding: 0;
				font-weight: 400;
			}
		}
		button {
			flex: 1;
			width: 100%;
			margin: 0 auto;
			padding: 8px 24px;
		}
		.timeout {
			padding-top: 20px 0;
			color: red;
		}
		.loader {
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100vw;
			height: var(--doc-height);
			background-color: #fffffe90;
			.loaderInner {
				position: fixed;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}
