@import '../../colorVariables.scss';

.pricing-container {
	width: 1400px;
	max-width: 100%;
	margin: 20px auto;
	padding: 20px;
	text-align: center;

	.pricing-cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 100%;
		text-align: left;
		gap: 20px;
		margin-bottom: 20px;
		.pricing-card {
			width: 320px;
			max-width: 100%;
			padding: 20px;
			border-radius: 10px;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
			min-height: 400px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.pricing-header {
				h2 {
					font-size: 1.5em;
					font-weight: 600;
					margin-bottom: 20px;
				}
				h3 {
					font-size: 1.2em;
					font-weight: 400;
				}
				p {
					font-weight: 400;
				}
			}

			hr {
				opacity: 0.1;
			}
			.pricing-card-bottom {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				min-height: 300px;
				.individual-description {
					display: flex;
					align-items: top;
					gap: 10px;
					.icon {
						color: #618d45;
						margin-top: 3px;
						min-height: fit-content;
						min-width: fit-content;
						font-size: 0.9em;
					}
					p {
						padding: 0;
						font-weight: 400;
						color: $charcoal;
					}
				}
			}
		}
	}

	.conversation-container {
		margin: 30px 0;
		margin-top: 100px;
		width: 100%;
		background-color: #094067;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 80px 0;
		border-radius: 10px;
		h1 {
			color: #fffffe;
			margin: 0;
		}

		p {
			color: #fffffe80;
			max-width: 400px;
			text-align: center;
			margin: 30px 0;
		}
	}
}

//  create a media width of less than 800px for the pricing card to be width of 100% please
@media only screen and (max-width: 800px) {
	.pricing-container {
		.pricing-cards {
			.pricing-card {
				width: 100%;
			}
		}
	}
}
