.blog-container {
	display: flex;
	flex-direction: column;
	min-height: var(--doc-height);
	.blogIndex {
		width: 100vw;
		max-width: 100%;
		background-color: #5f6c7b;
		height: 100%;
		flex: 1;

		.cardBackground {
			padding: 30px 0px 50px 0px;
			height: 100%;

			.cardContainer {
				width: 1360px;
				max-width: 100%;
				margin: auto;
				padding: 20px;
				text-align: left;
				.h1 {
					color: #fffffe;
					font-size: 2.5em;
					font-weight: 600;
				}
				.h2 {
					color: #fffffe;
					font-size: 1.5em;
					font-weight: 400;
					margin-bottom: 40px;
				}
				.cardHolder {
					display: flex;
					flex-wrap: nowrap;
					overflow-x: auto;
					gap: 20px;
					margin-top: 20px;
					max-width: 100vw;
					padding: 20px 0;

					&::-webkit-scrollbar {
						width: 10px; /* Width of the scrollbar */
					}

					&::-webkit-scrollbar-track {
						background: #fffffe; /* Color of the track (the space behind the scrollbar) */
					}

					&::-webkit-scrollbar-thumb {
						background: #5f6c7b80; /* Color of the actual scroll thumb */
					}

					&::-webkit-scrollbar-thumb:hover {
						background: #90b4ce; /* Color of the scroll thumb when you hover over it */
					}
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	.blogIndex {
		.cardBackground {
			.cardContainer {
				.h1 {
					font-size: 2.1em;
				}
				.h2 {
					font-size: 1.4em;
				}
				h3 {
					font-size: 1.2em;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.blogIndex {
		.cardBackground {
			.cardContainer {
				.h1 {
					font-size: 2em;
				}
				.h2 {
					font-size: 1.3em;
				}
				h3 {
					font-size: 1.1em;
				}
			}
		}
	}
}

/* Mobile devices */
@media (max-width: 480px) {
	.blogIndex {
		.cardBackground {
			.cardContainer {
				.h1 {
					font-size: 1.6em;
				}
				.h2 {
					font-size: 1.2em;
				}
				h3 {
					font-size: 1.1em;
				}
			}
		}
	}
}
