@import '/src/colorVariables.scss';

.singleJobPage {
	display: inline-block;
	width: 100%;
	min-height: 80vh;
	background-color: $background-light-cream;

	.jobContainer {
		margin-top: 20px;
		margin-bottom: 20px;
		border-radius: 5px;
		padding: 20px;
		display: inline-block;
		width: 800px;
		max-width: 90vw;
		background-color: #fffffe;
		text-align: left;
		.header {
			display: flex;
			justify-content: space-between;
			align-content: center;
			align-self: center;
			flex-wrap: wrap;
		}
	}
}

// Media Queries

@media (max-width: 1095px) {
	.singleJobPage {
		.controller {
			width: 800px;
			max-width: 90vw;
		}
	}
}
