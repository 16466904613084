@import '../../colorVariables.scss';

.other-profile-wrap {
	width: 1160px;
	max-width: 100%;
	margin: 20px auto;
	margin-bottom: 40px;
	padding-left: 10px;
	padding-right: 10px;

	h1 {
		color: $dark-charcoal;
	}

	.otherProfileContainer {
		border-radius: 5px;
		background-color: $background-grey;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		.left-side {
			width: 250px;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 10px;

			.editProfileSubContainer2 {
				background-color: $white;
				box-shadow:
					rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
					rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
				border-radius: 5px;
				text-align: left;
				height: fit-content;
				h3 {
					color: $dark-charcoal;
					font-weight: 600;
					font-size: 1.2em;
					.plusIcon {
						cursor: pointer;
						transition: 0.3s;
						&:hover {
							transform: scale(1.2);
						}
					}
				}
				h4 {
					color: $dark-charcoal;
					font-weight: 500;
					font-size: 1.1em;
				}
				h5 {
					color: $charcoal;
					font-weight: 400;
					font-size: 1em;
				}
				.skill {
					p {
						padding: 4px 8px;
						margin: 0;
						font-weight: 600;
					}
					.icon {
						font-size: 22px;
						vertical-align: middle;
						margin-bottom: 2px;
						cursor: pointer;
						margin-left: 5px;
						&:hover {
							color: #ef4565;
						}
					}
					display: inline-block;
					border: 1.5px solid $light-blue;
					background-color: $light-blue;
					color: $dark-blue;
					margin-right: 10px;
					margin-bottom: 10px;
					border-radius: 5px;
				}
			}
		}

		.right-side {
			width: 650px;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 10px;
			background-color: $white;
			padding: 20px;
			box-shadow:
				rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
				rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
			border-radius: 5px;
			text-align: left;
			height: fit-content;
			h2 {
				color: $dark-charcoal;
				font-weight: 700;
				font-size: 1.3em;
			}
		}

		.circle-image {
			width: 80px;
			height: 80px;
			border-radius: 0.5em;
			object-fit: cover;
			z-index: 1;
			transition: 0.3s;
			margin-bottom: 10px;
		}
		.editProfileSubContainer {
			background-color: $white;
			display: flex;
			width: 100%;
			.column {
				flex: 1;
			}
			h3 {
				color: $dark-charcoal;
				font-weight: 600;
				font-size: 1.2em;
				.plusIcon {
					cursor: pointer;
					transition: 0.3s;
					&:hover {
						transform: scale(1.2);
					}
				}
			}
			h4 {
				color: $dark-charcoal;
				font-weight: 500;
				font-size: 1.1em;
			}
			h5 {
				color: $charcoal;
				font-weight: 400;
				font-size: 1em;
			}
			.workExperience {
				background-color: #fffffe;
				margin-top: 10px;
				border-radius: 5px;
				.description {
					max-height: 300px;
					overflow-y: auto;
					padding-right: 20px;
					word-wrap: break-word;
				}
			}
		}

		.editProfileSubContainer3 {
			h3 {
				color: $dark-charcoal;
				font-weight: 600;
				font-size: 1.2em;
				.plusIcon {
					cursor: pointer;
					transition: 0.3s;
					&:hover {
						transform: scale(1.2);
					}
				}
			}
			.workExperience-2 {
				background-color: #fffffe;
				margin-top: 10px;
				border-radius: 5px;
				box-shadow:
					rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
					rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
				padding: 20px;
				text-align: left;
				h4 {
					color: $dark-charcoal;
					font-weight: 500;
					font-size: 1.1em;
				}
				h5 {
					color: $charcoal;
					font-weight: 400;
					font-size: 1em;
				}
				.workExperience {
					background-color: #fffffe;
					margin-top: 10px;
					border-radius: 5px;
					.description {
						max-height: 300px;
						overflow-y: auto;
						padding-right: 20px;
						word-wrap: break-word;
					}
				}
				.description {
					max-height: 300px;
					overflow-y: auto;
					padding-right: 20px;
					word-wrap: break-word;
				}
			}
		}
	}
}
