@import '../../colorVariables.scss';
.email-verified-page {
	background-color: $background-grey;
	.outer-email-box {
		padding: 20px;
		margin: 0 auto;
		width: 100%;
		.email-box {
			padding: 20px;
			background-color: $white;
			width: 500px;
			max-width: 100%;
			border-radius: 5px;
			margin: 0 auto;
			box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
			display: flex;
			flex-direction: column;
			gap: 15px;
			h1 {
				color: $dark-charcoal;
			}
			.message {
				color: $charcoal;
				margin: 0;
			}
		}
	}
}
