@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

// Import Bootstrap variables and functions
@import '~bootstrap/scss/functions';

$custom-font: 'Plus Jakarta Sans', sans-serif;
$font-family-base: $custom-font;

// Override Bootstrap variables with custom colors
$primary: #094067;
$secondary: #5f6c7b;
$success: #3da9fc;
$error: #ef4565;
$warning: #ef4565;
$info: #5f6c7b;
$light: #fffffe;
$dark: #094067;

// permwork colors
$white: #fffffe;
$whiteless: #fffffe60;
$navy: #094067;
$navyless: #09406790;
$blue: #3da9fc;
$light-blue: #d8eefe;
$light-blue-ish: #d8eefe80;
$grey: #5f6c7b;
$red: #ef4565;

// text colors
$text-color-primary: #094067;
$text-color-secondary: #5f6c7b;

@import '~bootstrap/scss/variables';

// Import the rest of Bootstrap
@import '~bootstrap/scss/bootstrap';

.card {
	color: $text-color-secondary;
	.card-title,
	.card-header {
		color: $text-color-primary;
	}
	&.text-secondary {
		color: $text-color-secondary;
	}
}

.btn {
	color: $light;
	border: 1px solid $primary;

	&:hover {
		color: $primary;
		background-color: $light;
		border: 1px solid $primary;
	}
}

.btn-primary {
	background-color: $primary;
	border: 1px solid $primary;

	&:hover {
		background-color: $light;
		color: $primary;
		border: 1px solid $primary;
	}
}

.btn-secondary {
	background-color: $secondary;
	border: 1px solid $secondary;

	&:hover {
		background-color: $light;
		color: $secondary;
		border: 1px solid $secondary;
	}
}

.btn-error {
	background-color: $error;
	border: 1px solid $error;

	&:hover {
		background-color: $light;
		color: $error;
		border: 1px solid $error;
	}
}

.btn-info {
	background-color: $info;
	border: 1px solid $info;

	&:hover {
		background-color: $light;
		color: $info;
		border: 1px solid $info;
	}
}

// Custom styles for list components
.list-group-item {
	color: $text-color-primary;

	&.text-secondary {
		color: $text-color-secondary;
	}
}
.typography {
	color: $text-color-primary;

	&.text-secondary {
		color: $text-color-secondary;
	}
}

.accordion-collapse {
	border-color: $light-blue; // Changes the border color of the accordion

	.accordion-body {
		color: $text-color-secondary; // Changes the text color of the accordion
		background-color: $light; // Changes the background color of the accordion
		&.text-secondary {
			color: $text-color-secondary;
			background-color: $dark; // Changes the background color when the text is secondary
		}
	}
}

.accordion-button {
	color: $text-color-primary; // Changes the text color of the accordion header
	background-color: $light; // Changes the background color of the accordion header

	&:not(.collapsed) {
		background-color: $light-blue-ish; // Changes the background color of the selected accordion header
	}
}

.bootstrap-subHeading {
	color: $text-color-primary;
	font-weight: bold;
}

// Custom focus styles for input elements
.form-control:focus {
	border-color: $warning;
	box-shadow: $warning;
}

// off canvas custom
.offcanvas {
	.offcanvas-header {
		color: $primary;
		background-color: #fffffe;
	}
	.offcanvas-body {
		background-color: $light;
		background-color: #fffffe;
	}
}

// modal custom
.modal {
	padding: 0;
}

.carousel-inner {
	height: 100% !important;
	display: flex !important;
	align-items: center !important;
}

.css-n211uj-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
