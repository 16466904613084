$white: #fffffe;
$whiteless: #fffffe90;
$navy: #094067;
$navyless: #09406790;
$dark-blue: #003366;
$blue: #3da9fc;
$light-blue: #d8eefe;
$light-blueless: #d8eefecd;
$grey: #5f6c7b;
$red: #ef4565;
$background-grey: #f5f5f5;
$charcoal: #3e4149;
$link-blue: #0077b6;

.browse-jobs-container {
	min-height: var(--doc-height);
	display: flex;
	flex-direction: column;
	max-width: 100%;
	.browseJobs {
		.header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 40px;
			background-color: $background-grey;
			margin-top: 0;
			.inner-header {
				width: 800px;
				max-width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: left;
				.subheader {
					max-width: 600px;
					font-weight: 500;
					color: $charcoal;
				}
				.closeButton {
					position: relative;
					color: #fffffe;
					font-size: 24px;
				}
				h2,
				h1 {
					color: $dark-blue;
					padding-bottom: 10px;
					font-weight: 500;
					text-align: left;
					width: 100%;
				}
				form {
					width: 100%;
					max-width: 100%;
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					align-items: center;
					gap: 10px;
					// input {
					// 	height: 50px;
					// 	padding: 0 20px;
					// 	border: 2px solid $navy;
					// 	min-width: 300px;
					// 	flex: 3;
					// 	margin: 0;
					// 	color: $charcoal;
					// 	&:focus {
					// 		border: 2px solid $dark-blue;
					// 		outline: none;
					// 	}
					// }
					// button {
					// 	flex: 1;
					// 	min-width: 100px;
					// 	height: 50px;
					// 	padding: 0;
					// 	margin: 0;
					// }
				}
				.filterContainer {
					margin-top: 20px;
					max-width: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					gap: 10px;
					max-width: 100%;

					h3 {
						font-size: 18px;
						font-weight: 500;
						margin: 0;
					}
					.filterItem {
						flex: 1;
						min-width: 150px;
						max-width: 250px;
						background-color: $white;
					}
				}
			}
		}

		.jobsContainer {
			.jobsList {
				.jobItem {
					width: 600px;
					max-width: 100%;
					margin: 0 auto;
					margin-bottom: 20px;
					border-radius: 3px;
					padding: 20px;
					min-height: 200px;
					text-align: left;
					cursor: pointer;
					&:hover {
						transition: 0.3s;
						box-shadow:
							rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
							rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
					}
					.jobItemHeader {
						h3 {
							font-size: 18px;
							font-weight: 500;
							margin: 0;
						}
					}
				}
				.secondContainer {
					.titleContainer {
						display: flex;
						min-width: 300px;
						margin: 0 auto;
						justify-content: center;
						text-align: center;
						max-width: 100%;

						h3 {
							cursor: pointer;
							padding: 30px 0;
							padding-bottom: 15px;
							margin: 0;
							width: 200px;
							font-size: 20px;
							font-weight: 400;
							color: $dark-blue;
						}
						.selected {
							font-weight: 600;
							border-bottom: 3px solid $dark-blue;
						}
					}
					hr {
						margin: 0;
						padding: 0;
						opacity: 0.1;
					}

					.feedContainer {
						margin: 0 auto;
						text-align: center;
						padding: 20px;
						padding-top: 0;
						.allFeeds {
							margin-top: 20px;
							// display: inline-block;
							// max-width: 100%;
						}
						.cardStyle {
							width: 500px;
							max-width: 100%;
							text-align: left;
							cursor: pointer;
							transition: 0.3s;
							border: 1px solid #e0e0e0;

							&:hover {
								box-shadow: $charcoal 0px 0px 0px 1px;
							}
						}
						.selectedAllCards {
							// box-shadow: $link-blue 0px 0px 0px 2px;
							box-shadow: $charcoal 0px 0px 0px 1px;
						}
						.feed {
							cursor: pointer;
							padding: 40px;
							background-color: #5f6c7b05;
							width: 400px;
							text-align: left;
							max-width: 95%;
							margin: 20px;
							margin-top: 0;
							border-radius: 5px;
							box-shadow:
								rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
								rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
							&:hover {
								background-color: #5f6c7b10;
							}
							h3 {
								font-size: 20px;
							}
						}

						.selectedFeed {
							display: inline-block;
							text-align: left;
							vertical-align: top;
							width: 700px;
							margin: 20px;
							border-radius: 5px;
							position: sticky;
							top: 20px;

							.heading {
								display: flex;
								justify-content: space-between;
								h3 {
									font-size: 20px;
									max-width: 70%;
									cursor: pointer;
									&:hover {
										text-decoration: underline 0.5px;
									}
								}
								.heart {
									color: #094067;
									cursor: pointer;
									font-size: 48px;
									background-color: #5f6c7b20;
									padding: 10px;
									border-radius: 5px;
									&:hover {
										color: #ef4565;
									}
								}
								.fill {
									color: #ef4565;
								}
							}
						}
						p {
							margin: 0;
						}
					}
				}
			}
		}
		.pages {
			display: inline-block;
			margin: 25px auto;
			margin-bottom: 10px;
		}
	}
}

.modalHeader {
	cursor: pointer;
	transition: 0.3s;
	padding-left: 10px;
	border: solid 1px #09406700;
	&:hover {
		text-decoration: underline;
	}
}

@media only screen and (max-width: 700px) {
	.browseJobs {
		max-width: 100%;
	}
}
