.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100vw;
	height: var(--doc-height);
	background-color: #fffffe90;
	z-index: 2000;
	.loaderInner {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.loaderPartial {
	margin: auto;
	height: var(--doc-height);
	z-index: 2000;
	display: flex;
	align-items: center;
	justify-content: center;
}
