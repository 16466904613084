@import '../../colorVariables.scss';

.postJobForm {
	h1 {
		color: $dark-charcoal;
		text-align: left;
		width: 800px;
		max-width: 95%;
		margin: 0 auto;
		padding: 20px 10px;
	}
}

.materialJob {
	padding: 40px;
	display: inline-block;
	margin: 0px auto;
	margin-bottom: 30px;
	border-radius: 10px;
	width: 800px;
	max-width: 95%;
	text-align: left;

	input,
	textarea {
		border-radius: 3px;
		&:focus {
			transition: 0.3s;
			box-shadow:
				rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
				rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
		}
	}
	select,
	.customCheckbox {
		display: flex;
		justify-content: flex-start !important;
	}
	.customSelect {
		padding: 8px;
		border-radius: 3px;
		margin: 5px auto;
	}
	button {
		color: #fffffe;
	}
	p {
		color: $charcoal;
	}
	.jobTypeSelections {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.jobTypeBox {
			width: 32%;
			height: 100px;

			padding: 10px;
			margin: auto 0;
			border: 2px solid #09406720;
			border-radius: 3px;
			cursor: pointer;
			transition: 0.2s;
			p {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
			}

			&:hover {
				border: 2px solid #09406760;
			}
		}
		.selectedJobType {
			border: 2px solid #09406780;
		}
	}
	.engagementType {
		justify-content: space-between;
		width: 380px;
		max-width: 95%;
		margin: 0 auto;
		.engagementBox {
			width: 184px;
		}
	}

	.questionsTab {
		display: inline-block;
	}
	.questionsList {
		text-align: left;
		.icon {
			cursor: pointer;
			margin: 5px;
			transition: 0.2s;

			&:hover {
				color: #ef4565;
			}
		}
	}

	.price-card {
		border-radius: 5px;
		cursor: pointer;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
		transition: 0.3s;

		&:hover {
			transform: scale(1.01);
		}
		input {
			cursor: pointer;
			margin-top: 6px;
		}
		.price-card-header {
			// wrap text
			white-space: normal;
			word-wrap: break-word;
			width: 200px;
			flex-grow: 1;
			background-color: $link-blue;
			border-radius: 5px;
			padding: 10px;
			display: flex;
			align-items: top;
			p {
				color: $white;
				margin-bottom: 0;
			}
		}
		.price-card-body {
			width: 360px;
			flex-grow: 1;
			padding: 20px;
			background-color: $white;
		}
		.price-card-body-selected {
			background-color: $background-grey;
			transition: 0.3s;
		}
	}
}
