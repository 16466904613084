@import './colorVariables.scss';

.buttonLight {
	// min-width: 150px;
	min-height: 45px;
	background-color: #3da9fc;
	color: #fffffe;
	border: solid 2px #3da9fc00;
	transition: 0.3s;

	&:hover {
		background-color: #fffffe00;
		color: #3da9fc;
		border: #3da9fc90 solid 2px;
	}
}

.buttonRed {
	min-height: 45px;
	background-color: #ef4565;
	color: #fffffe;
	border: solid 2px #ef456500;
	transition: 0.3s;

	&:hover {
		background-color: #fffffe00;
		color: #ef4565;
		border: #ef456580 solid 2px;
	}
}

.buttonRedSmall {
	background-color: #ef4565;
	color: #fffffe;
	border: solid 1px #ef456500;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #fffffe00;
		color: #ef4565;
		border: #ef456580 solid 1px;
	}
}

.buttonNavy {
	background-color: #094067;
	color: #fffffe;
	border: solid 2px #09406700;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #fffffe00;
		color: #094067;
		border: #09406790 solid 2px;
	}
}

.buttonDarkBlue {
	background-color: $dark-blue;
	color: #fffffe;
	border: solid 2px $dark-blue;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #fffffe00;
		color: $dark-blue;
		border: $dark-blue solid 2px;
	}
}

.buttonLinkBlue {
	background-color: #0077b6;
	color: #fffffe;
	border: solid 2px #0077b600;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #fffffe00;
		color: #0077b6;
		border: #0077b690 solid 2px;
	}
}

.buttonGrey {
	background-color: #5f6c7b;
	color: #fffffe;
	border: solid 2px #5f6c7b00;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #fffffe00;
		color: #5f6c7b;
		border: #5f6c7b90 solid 2px;
	}
}

.buttonSecondary {
	background-color: #90b4ce;
	color: #fffffe;
	border: solid 2px #90b4ce00;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #fffffe00;
		color: #90b4ce;
		border: #90b4ce solid 2px;
	}
}

.buttonLightBlue {
	background-color: #d8eefe;
	color: #094067;
	border: solid 2px #d8eefe90;
	min-height: 45px;

	&:hover {
		background-color: inherit;
		color: #d8eefe;
		border: #d8eefe90 solid 2px;
	}
}

.buttonSkeletonLinkBlue {
	background-color: inherit;
	color: #0077b6;
	border: solid 2px #0077b6;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #0077b6;
		color: #fffffe;
		border: #0077b6 solid 2px;
	}
}

.buttonSkeletonNavy {
	background-color: #fffffe;
	color: #094067;
	border: solid 2px #094067;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #094067;
		color: #fffffe;
		border: #094067 solid 2px;
	}
}

.buttonSkeletonGrey {
	background-color: #fffffe;
	color: #5f6c7b;
	border: solid 2px #5f6c7b80;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #5f6c7b;
		color: #fffffe;
		border: #5f6c7b80 solid 2px;
	}
}

.buttonSkeletonWhite {
	background-color: inherit;
	color: #fffffe;
	border: solid 2px #fffffe80;
	transition: 0.3s;
	min-height: 45px;

	&:hover {
		background-color: #d8eefe;
		color: #094067;
	}
}

a {
	text-decoration: none;
	color: #094067;
}

input {
	color: #094067;
	background-color: white;
	border-radius: 3px;
	border: solid 1px #09406780;
}

.standard-link {
	color: $link-blue;
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		color: $blue;
	}
}

.linkButton {
	border: 1.5px solid #09406760;
	background-color: #094067;
	color: #fffffe;
	padding: 6px 18px;
	border-radius: 5px;
	transition: 0.3s;
	margin-right: 5px;
	min-width: 85px;
	text-align: center;
	font-weight: 600;

	&:hover {
		background-color: #fffffe;
		color: #094067;
	}
	&:disabled {
		background-color: #5f6c7b;
		color: #fffffe;
		border: 1px solid #5f6c7b;
		opacity: 0.5;
	}
}

.linkButtonDarkBlue {
	border: 2px solid #00336690;
	background-color: #003366;
	color: #fffffe;
	padding: 6px 18px;
	border-radius: 5px;
	transition: 0.3s;
	text-align: center;
	font-weight: 600;
	&:hover {
		background-color: inherit;
		color: #003366;
	}
	&:disabled {
		background-color: #5f6c7b;
		color: #fffffe;
		border: 2px solid #5f6c7b;
		opacity: 0.5;
	}
}

.linkButtonLinkBlue {
	border: 2px solid #0077b690;
	background-color: #0077b6;
	color: #fffffe;
	padding: 6px 18px;
	border-radius: 5px;
	transition: 0.3s;
	text-align: center;
	font-weight: 600;
	&:hover {
		background-color: inherit;
		color: #0077b6;
	}
	&:disabled {
		background-color: #5f6c7b;
		color: #fffffe;
		border: 2px solid #5f6c7b;
		opacity: 0.5;
	}
}

.linkButtonRed {
	border: 2px solid #ef456590;
	background-color: $red;
	color: #fffffe;
	padding: 6px 18px;
	border-radius: 5px;
	transition: 0.3s;
	text-align: center;
	font-weight: 600;
	&:hover {
		background-color: inherit;
		color: $red;
	}
	&:disabled {
		background-color: #5f6c7b;
		color: #fffffe;
		border: 2px solid #5f6c7b;
		opacity: 0.5;
	}
}

.linkButtonGrey {
	border: 2px solid $grey;
	background-color: $grey;
	color: #fffffe;
	padding: 6px 18px;
	border-radius: 5px;
	transition: 0.3s;
	text-align: center;
	font-weight: 600;
	cursor: pointer;
	&:hover {
		background-color: inherit;
		color: $grey;
	}
	&:disabled {
		background-color: #5f6c7b;
		color: #fffffe;
		border: 2px solid #5f6c7b;
		opacity: 0.5;
	}
}

.linkButtonDarkBlue {
	border: 2px solid #00336690;
	background-color: #003366;
	color: #fffffe;
	padding: 6px 18px;
	border-radius: 5px;
	transition: 0.3s;
	text-align: center;
	font-weight: 600;
	&:hover {
		background-color: inherit;
		color: #003366;
	}
	&:disabled {
		background-color: #5f6c7b;
		color: #fffffe;
		border: 2px solid #5f6c7b;
		opacity: 0.5;
	}
}

.linkButtonSkeletonDarkBlue {
	border: 2px solid #00336690;
	background-color: inherit;
	color: $dark-blue;
	padding: 6px 18px;
	border-radius: 5px;
	transition: 0.3s;
	text-align: center;
	font-weight: 600;
	&:hover {
		background-color: $dark-blue;
		color: $white;
	}
	&:disabled {
		background-color: #5f6c7b;
		color: #fffffe;
		border: 2px solid #5f6c7b;
		opacity: 0.5;
	}
}

.linkButtonSkeletonLinkBlue {
	border: 2px solid #0077b6;
	background-color: inherit;
	color: $link-blue;
	padding: 6px 18px;
	border-radius: 5px;
	transition: 0.3s;
	text-align: center;
	font-weight: 600;
	&:hover {
		background-color: $link-blue;
		color: $white;
	}
	&:disabled {
		background-color: #5f6c7b;
		color: #fffffe;
		border: 2px solid #5f6c7b;
		opacity: 0.5;
	}
}

.linkButtonLight {
	background-color: #3da9fc;
	color: #fffffe;
	border: solid 2px #3da9fc00;
	transition: 0.3s;
	padding: 10px 22px;
	border-radius: 5px;
	font-weight: 600;

	&:hover {
		background-color: #fffffe00;
		color: #3da9fc;
		border: #3da9fc90 solid 2px;
	}
}

.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100vw;
	height: var(--doc-height);
	background-color: #fffffe90;
	.loaderInner {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.error {
	color: #ef4565;
}

.error-border {
	border: 1px solid $red;
	color: $red;
	margin: 5px 0;
	padding: 5px;
	border-radius: 5px;
}

.center {
	display: flex;
	justify-content: center;
	margin: 10px 0;
}

.smallPadding {
	padding: 15px;
	margin: 0;
}

.itemTagBlue {
	background-color: #3da9fc10;
	color: $link-blue;
	border: 1px solid $link-blue;
	padding: 6px 12px;
	border-radius: 5px;
	font-weight: 500;
}

.itemTagRed {
	background-color: #5f6c7b10;
	color: #5f6c7b;
	border: 1px solid #5f6c7b80;
	padding: 6px 12px;
	border-radius: 5px;
	font-weight: 500;
}

.itemTagGrey {
	background-color: $background-grey;
	color: $charcoal;
	padding: 6px 12px;
	border-radius: 5px;
	font-weight: 500;
	font-size: 0.9em;
	min-width: fit-content;
	height: fit-content;
	display: flex;
	align-items: center;
	gap: 5px;
}

.itemTagLinkBlue {
	background-color: $light-blueless;
	color: $link-blue;
	padding: 6px 12px;
	border-radius: 5px;
	font-weight: 500;
	font-size: 0.9em;
	display: flex;
	align-items: center;
	gap: 5px;
}

.itemTagLinkBlueFilled {
	background-color: $link-blue;
	color: $white;
	padding: 6px 12px;
	border-radius: 5px;
	font-weight: 500;
	font-size: 0.9em;
	display: flex;
	align-items: center;
	gap: 5px;
}

.bold {
	font-weight: 600;
}

.card-shadow {
	box-shadow:
		rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
		rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.cardDesign {
	border: 0px;
	border-radius: 5px;
	padding: 5px;
	.cardHeaderDesign {
		background-color: #fffffe00;
		border: none;
		display: flex;
		justify-content: space-between;
		align-items: top;
		gap: 10px;
		color: $dark-blue;
		font-weight: 600;
		font-size: 1.1em;
		margin: 0;
		.iconed-header {
			display: flex;
			align-items: top;
			gap: 10px;
		}
	}
	.card-body-design {
		color: $charcoal;
		font-size: 1em;
		font-weight: 400;
		padding-top: 0;
		display: flex;
		flex-direction: column;
		gap: 5px;

		.body-created {
			display: flex;
			align-items: center;
			gap: 5px;
			padding-top: 10px;
			font-size: 0.9em;
			color: $grey;
			.icon {
				font-size: 18px;
			}
		}
		.body-job {
			background-color: $background-grey;
			font-weight: 600;
			display: flex;
			align-items: top;
			gap: 10px;
			padding: 5px 10px;
			border-radius: 5px;
			font-size: 0.9em;
		}
		.body-category {
			background-color: $background-grey;
			display: flex;
			align-items: top;
			gap: 10px;
			padding: 5px 10px;
			border-radius: 5px;
			font-size: 0.9em;
		}
		.body-engagement {
			display: flex;
			align-items: top;
			gap: 10px;
			background-color: $light-blueless;
			padding: 5px 10px;
			border-radius: 5px;
			font-size: 0.9em;
		}
		.body-job-opened {
			font-weight: 500;
			color: $dark-blue;
			font-size: 1em;
			display: flex;
			align-items: top;
			gap: 10px;
			span {
				text-decoration: underline;
				&:hover {
					color: $blue;
				}
			}
		}
		.body-created {
			font-size: 0.8em;
		}
	}
	.cardFooterDesign {
		background-color: #fffffe00;
		color: $charcoal;
		border: none;
	}
}

.description {
	// margin-top: 10px;
	// height: 100px;
	// overflow-y: auto;
	// padding: 15px;
	// border-radius: 5px;
	// word-wrap: break-word;
}

@media (max-width: 768px) {
	h1 {
		font-size: 2em;
	}
	h2 {
		font-size: 1.3em;
	}
	h3 {
		font-size: 1.1em;
	}
	h4 {
		font-size: 1.1em;
	}
}

/* Mobile devices */
@media (max-width: 480px) {
	.mobile-width-100 {
		max-width: 100%;
		width: 100%;
		min-width: 100%;
	}
	h1 {
		font-size: 1.5em;
	}
	h2 {
		font-size: 1.2em;
	}
	h3 {
		font-size: 1.1em;
	}
}

.h1-mini {
	font-size: 0.9em;
	font-weight: 600;
	color: #5f6c7b;
	margin: 10px 0;
}

.dim-hr {
	border-color: #5f6c7b70;
}

.fake-button {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* permColors.css */
.color-white {
	color: $white !important;
}

.color-whiteless {
	color: $whiteless !important;
}

.color-navy {
	color: $navy !important;
}

.color-navyless {
	color: $navyless !important;
}

.color-darkBlue {
	color: $dark-blue !important;
}

.color-blue {
	color: $blue !important;
}

.color-deepBlue {
	color: $deep-blue !important;
}
.color-lightBlue {
	color: $light-blue !important;
}
.color-lightBlueless {
	color: $light-blueless !important;
}
.color-grey {
	color: $grey !important;
}
.color-red {
	color: $red !important;
}
.color-backgroundGrey {
	background-color: $background-grey !important;
}
.color-charcoal {
	color: $charcoal !important;
}
.color-linkBlue {
	color: $link-blue !important;
}
.color-darkCharcoal {
	color: $dark-charcoal !important;
}

.ck-content {
	border-radius: 0 0 5px 5px !important; /* Adjust the radius as needed */
	min-height: 400px;
	resize: vertical;
}
.ck-toolbar {
	border-radius: 5px 5px 0 0 !important; /* Adjust the radius as needed */
}

.ck-powered-by {
	display: none !important;
}

.ck-content ul,
.ck-content ol {
	padding-left: 2rem;
	margin: 1em 0;
}

.ck-content ul li {
	list-style-type: disc;
	background: none;
}

.ck-content ol li {
	list-style-type: decimal;
	background: none;
}

.ck-content li {
	padding: 0.2em 0;
	background: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	background-color: transparent !important;
}

/* Firefox */
input:-moz-ui-valid {
	background-color: transparent !important;
}

.download-link {
	color: $deep-blue;
	border: 1px solid $deep-blue;
	padding: 6px 18px;
	border-radius: 5px;
	transition: 0.3s;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
	display: flex;
	align-items: center;
	gap: 5px;
	width: fit-content;
	&:hover {
		box-shadow: none;
		color: $link-blue;
		border: 1px solid $link-blue;
	}
}

.working-icon {
	background-color: $background-grey;
	padding: 8px;
	width: 40px !important;
	height: 40px !important;
	border-radius: 50%;
}

.w-auto {
	width: auto;
}

.shadow-border {
	box-shadow:
		rgba(0, 0, 0, 0.05) 0px 3px 12px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}
.shadow-border-chill-background {
	box-shadow:
		rgba(0, 0, 0, 0.03) 0px 2px 8px 0px,
		rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
}

.shadow-border-no-background {
	box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

.bg-permFun {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg fill-opacity='0.42'%3E%3Cpolygon fill='%23394782' points='800 100 0 200 0 800 1600 800 1600 200'/%3E%3Cpolygon fill='%235b6396' points='800 200 0 400 0 800 1600 800 1600 400'/%3E%3Cpolygon fill='%237c80aa' points='800 300 0 600 0 800 1600 800 1600 600'/%3E%3Cpolygon fill='%239c9ebf' points='1600 800 800 400 0 800'/%3E%3Cpolygon fill='%23bcbdd4' points='1280 800 800 500 320 800'/%3E%3Cpolygon fill='%23dddee9' points='533.3 800 1066.7 800 800 600'/%3E%3Cpolygon fill='%23FFFFFE' points='684.1 800 914.3 800 800 700'/%3E%3C/g%3E%3C/svg%3E");
	background-size: cover;
	background-image: url('../public/subtle-prism.svg');

	border-bottom: 8px solid $blue;
	// border-top: 8px solid $blue;
}

.bg-perm-gradient {
	background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(251, 249, 253, 1) 50%, rgba(253, 250, 253, 1) 100%);
}

.shadow-subtle {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
