$white: #fffffe;
$whiteless: #fffffe60;
$navy: #094067;
$navyless: #09406790;
$blue: #3da9fc;
$light-blue: #d8eefe;
$grey: #5f6c7b;
$red: #ef4565;

.enquiry-form {
	text-align: left;
	.header {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		h2 {
			font-size: 1.3em;
		}
	}
	p {
		color: $grey;
		a {
			text-decoration: underline;
		}
	}

	.thankyou-text {
		.social-links {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			a {
				display: flex;
				gap: 10px;
				align-items: center;
			}
		}
	}

	form {
		display: flex;
		flex-direction: column;
		gap: 30px;
		p {
			margin: 0;
			color: $grey;
			font-size: 0.9em;
			a {
				text-decoration: underline;
			}
		}
		h2 {
			font-weight: 700;
		}
		input,
		select {
			flex: 1;
			padding: 10px;
			min-width: 150px;
		}
		.names,
		.phone {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;

			.code {
				flex: 1;
			}
			.number {
				flex: 8;
			}
		}
	}
}
