.contractPage {
	.contractContainer {
		display: inline-block;
		width: 500px;
		min-height: 65vh;
		max-width: 95%;
		margin: 0 auto;

		border-radius: 5px;
		padding: 20px;
		text-align: left;
	}

	.custom-checkbox-label {
		display: flex;
		justify-content: flex-start;
		text-align: left;
		align-items: left;
		margin-bottom: 5px !important;
		margin-top: 20px;
	}

	.custom-checkbox-label input[type='checkbox'] {
		margin-right: 10px;
	}

	.icon {
		cursor: pointer;
		margin: 5px;
		transition: 0.2s;
		&:hover {
			color: #ef4565;
		}
	}

	.select-placeholder {
		color: gray;
	}
}

.contractSuccess {
	min-height: 65vh;
	width: 500px;
	max-width: 95%;
	margin: 0 auto;

	vertical-align: middle;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
