$white: #fffffe;
$whiteless: #fffffe60;
$navy: #094067;
$navyless: #09406790;
$blue: #3da9fc;
$light-blue: #d8eefe;
$grey: #5f6c7b;
$red: #ef4565;

.faq {
	h1 {
		font-size: 2em;
	}
	h2 {
		font-size: 1.5em;
	}
	h3 {
		font-size: 1.3em;
	}
	h4 {
		font-size: 1.1em;
	}

	width: 960px;
	max-width: 100%;
	margin: 0 auto;
	padding: 20px;
	text-align: left;

	.title {
		padding-top: 30px;
		padding-bottom: 10px;
		font-weight: 600;
	}

	.accordian {
		border-radius: 5px;
	}
	.card-container {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		margin: 20px 0;

		.card {
			flex: 1;
			min-width: 300px;
			max-width: 100%;
		}
	}
}

@media (max-width: 768px) {
	.faq {
		h1 {
			font-size: 1.7em;
		}
		h2 {
			font-size: 1.3em;
		}
		h3 {
			font-size: 1.1em;
		}
		h4 {
			font-size: 1.1em;
		}
	}
}

/* Mobile devices */
@media (max-width: 480px) {
	.faq {
		h1 {
			font-size: 1.5em;
		}
		h2 {
			font-size: 1.2em;
		}
		h3 {
			font-size: 1.1em;
		}
	}
}
