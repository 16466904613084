$white: #fffffe;
$whiteless: #fffffe90;
$navy: #094067;
$navyless: #09406790;
$dark-blue: #003366;
$blue: #3da9fc;
$light-blue: #d8eefe;
$light-blueless: #d8eefecd;
$grey: #5f6c7b;
$red: #ef4565;
$background-grey: #f5f5f5;
$charcoal: #3e4149;
$link-blue: #0077b6;
$deep-blue: #082e6e;
$dark-charcoal: #2b2d33;

.dashboardComponent {
	text-align: left;
	max-height: var(--doc-height);
	overflow-y: auto;
	.header {
		margin: 5px 15px;
		margin-top: 20px;
		text-align: left;
		border-radius: 10px;
		z-index: 1;
		h2 {
			font-size: 20px;
			font-weight: 600;
			margin: 0;
			color: $dark-charcoal;
			text-align: left;
			padding: 5px;
		}
		.jobHeader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			margin-top: 20px;

			h2 {
				font-size: 20px;
				font-weight: 600;
				margin: 0;
				color: $dark-charcoal;
				text-align: left;
				padding: 5px;
			}

			.buttonLinkBlue {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				min-height: fit-content;
				padding: 5px 10px;
				font-weight: 400;
				p {
					margin: 0;
				}
				.icon {
				}
			}
		}
	}

	// MESSAGING COMPONENT //////////////////////////////////////////////

	.outerContainer {
		padding: 15px;
		padding-bottom: 0;
		padding-top: 0;
		max-width: 100%;
		overflow-y: auto;

		.jobContainer {
			display: inline-block;
			width: 100%;
			max-width: 100%;
			border-radius: 5px;
			padding-top: 0px;
			overflow-x: hidden;
			margin: 0;

			.job {
				border-radius: 5px;
				margin: 30px auto;
				margin-bottom: 15px;
				margin-top: 0;
				max-width: 100%;
				.applicants {
					display: inline-block;
					width: 250px;
					max-width: 100%;
					vertical-align: top;
					margin-right: 20px;
					margin-bottom: 20px;
					p {
						color: #094067;
					}
					.applicantContent {
						max-height: 300px;
						overflow-y: auto;
						background-color: #1d588310;
						padding: 10px 0;
						padding-right: 20px;
						padding-left: 10px;
						border-radius: 5px;
					}
				}
				.jobItem {
					max-height: 300px;
					max-width: 100%;
					h4,
					h5 {
						color: #094067;
					}
					.header {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0;
						h4 {
							&:hover {
								color: #ef4565;
								cursor: pointer;
							}
						}
					}

					p {
						margin-bottom: 5px;
					}
					.description {
						padding: 10px;
						background-color: #d8eefe50;
						overflow-y: auto;
						max-height: 150px;
						max-width: 100%;
					}
				}
			}
		}
		.pagination {
			display: flex;
			justify-content: center;
			padding: 20px 0;
		}
		.cardInnerContainer {
			padding: 20px;
			max-height: 900px;
			background-color: #fffffe;
			border-radius: 10px;
			overflow-y: auto;
			width: 600px;
			max-width: 100%;
			h4 {
				margin-bottom: 20px;
			}
			.addPaymentMethodForm {
				max-width: 600px;
				button {
					display: block;
					margin-top: 20px;
					float: right;
				}
				h4 {
					font-weight: 600;
				}
			}
		}

		.subscriptionContainer {
			.subscriptionItem {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				border-radius: 10px;
				margin: 0;
				margin-bottom: 10px;
				width: 550px;
				max-width: 100%;
				background-color: #fffffe;
				h4 {
					color: #094067;
				}
				.jobStatus {
					padding: 10px 20px;
					background-color: #cccccc30;
					border-radius: 5px;
				}
				button {
					margin-top: 10px;
					width: 100%;
				}
				p {
					margin: 3px 0;
				}
			}
		}
	}
}

.card-element-container {
	border: 1px solid #cccccc;
	border-radius: 4px;
	padding: 8px;
	width: 100%;
}
.credit-card {
	width: 300px;
	max-width: 100%;

	background-color: #094067;
	background-image: linear-gradient(43deg, $deep-blue 0%, $deep-blue 46%, $link-blue 100%);

	border-radius: 10px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: #fff;
	position: relative;
	margin-bottom: 40px;

	.credit-card__header {
		display: flex;
		justify-content: flex-end;
		.credit-card__bank-name {
			font-size: 1.2rem;
			font-weight: 600;
		}
	}

	.credit-card__body {
		.credit-card__chip {
			width: 40px;
			height: 30px;
			background-color: #fff;
			border-radius: 5px;
			position: absolute;
			top: 60px;
			left: 20px;
		}

		.credit-card__number {
			margin-top: 60px;
			font-size: 1.2rem;
			font-weight: 600;
			letter-spacing: 1.5px;
			text-align: left;
		}

		.credit-card__details {
			display: flex;
			justify-content: space-between;
			font-size: 0.8rem;

			.credit-card__cardholder,
			.credit-card__expiry {
				display: flex;
				flex-direction: column;

				label {
					font-weight: 500;
					margin-bottom: 2px;
				}
			}
		}
	}
}

// mobile width less than 800px
@media (max-width: 800px) {
	.outerContainer {
		padding: 0;
	}
	.dashboardComponent {
		.header {
			text-align: center;
			.jobHeader {
				.button {
				}
			}
		}
	}
}
