.salary-guide {
	display: flex;
	flex-direction: column;
	min-height: var(--doc-height);
	max-width: 100%;

	.salary-container {
		padding: 60px 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 60px;
		text-align: left;
		margin: auto 0;

		.salary-header {
			max-width: 800px;
			h3 {
				font-size: 1.2em;
				margin-bottom: 20px;
			}
			p {
				margin-top: 0;
			}
			.linkButtonLight {
				display: inline-block;
			}
		}
		.salary-content {
			max-width: 400px;

			img {
				max-width: 320px;
				border-radius: 50%;
				box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
			}
			.square-image {
				border-radius: 0;
				width: 100%;
				box-shadow: none;
			}
		}
	}
	.footer {
		margin-top: auto;
		margin-bottom: 0;
	}
}
