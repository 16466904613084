$white: #fffffe;
$whiteless: #fffffe60;
$color2: rgba(0, 0, 0, 0.1);
$navy: #094067;
$naviless: #09406790;
$light-blue: #d8eefe;
$color6: rgba(99, 99, 99, 0.2);
$color7: aliceblue;
$white: #fffffe;
$whiteless: #fffffe90;
$navy: #094067;
$navyless: #09406790;
$dark-blue: #003366;
$blue: #3da9fc;
$light-blue: #d8eefe;
$light-blueless: #d8eefecd;
$grey: #5f6c7b;
$red: #ef4565;
$background-grey: #f5f5f5;
$charcoal: #3e4149;
$link-blue: #0077b6;
.navbar-dark {
	width: 100%;
	background-color: $dark-blue;
	ul {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		padding: 10px 40px;
		list-style: none;
		align-items: center;
		width: 100%;
		min-height: 80px;
		margin: auto 0;

		.inline {
			display: flex;
			justify-content: space-between;
			margin: auto 0;
			vertical-align: middle;

			.jobs {
				text-decoration: none;
				padding: 6px 18px;
				border-radius: 5px;
				border: 2px solid $whiteless;
				transition: 0.3s;
				margin-right: 5px;
				margin-left: 20px;
				color: $white;

				&:hover {
					background-color: $white;
					color: $dark-blue;
				}
			}
			.notificationsIcon {
				color: $dark-blue;
				background-color: $light-blue;
				padding: 7px;
				margin-left: 10px;
				border-radius: 10px;
				box-sizing: content-box;
				cursor: pointer;
				transition: 0.3s;
				&:hover {
					background-color: $dark-blue;
					color: $light-blue;
				}
			}
			.hidden {
				display: none;
			}
			.visible {
				display: flex;
				flex-direction: column;
				position: absolute;
				z-index: 2;
				background-color: $white;
				margin-left: -80px;
				margin-top: 15px;
				padding: 5px;
				border-radius: 3px;
				box-shadow: $color6 0px 2px 8px 0px;
				max-width: 138px;

				a {
					text-decoration: none;
					z-index: 2;
					color: $white;

					&:hover {
						background-color: $dark-blue;
						color: $dark-blue;
					}
				}
				p {
					cursor: pointer;
					z-index: 2;
					overflow-x: hidden;
					background-color: $white;
					text-decoration: none;
					border-radius: 3px;
					padding: 6px;
					margin: 0;
					color: $dark-blue;
					&:hover {
						background-color: $dark-blue;
						color: $white;
					}
				}
				h6 {
					overflow-x: hidden;
					text-overflow: ellipsis;
					z-index: 2;
					font-size: calc(16px);
					margin: 0;
					padding: 6px;
				}
				.pointer {
					display: flex;
					position: absolute;
					top: -15px;
					right: 10px;
					z-index: 0.9;
					height: 40px;
					width: 40px;
					background: $white;
					margin: 0 auto;
					transform: rotate(45deg);
					border-radius: 0 0 3px 0;
					margin-top: 12px;
					margin-left: 6.5px;
				}
			}
		}
		.inline2 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.logo {
				padding-bottom: 8px;
				margin-right: 15px;
			}
		}
		li {
			margin: auto 0;
			vertical-align: middle;
			a {
				text-decoration: none;
				padding: 8px 18px;
				border-radius: 3px;
				color: $white;
			}

			button {
				padding: 4px 14px;
				vertical-align: middle;
				border-radius: 3px;
			}
			.hamburger {
				color: $light-blue;
			}
		}
	}
	.menu {
		display: none;
		visibility: collapse;

		.jobs {
			text-decoration: none;
			padding: 6px 18px;
			border-radius: 3px;
			border: 1px solid $white;
			transition: 0.3s;
			margin-right: 5px;
			color: $white;
			&:hover {
				background-color: $dark-blue;
				color: $dark-blue;
			}
		}
	}
	.searchList {
		position: relative;
		input {
			border: 1px solid $naviless;
			padding: 5px;
			padding-right: 20px;
			padding-left: 30px;
		}
		.searchy {
			position: absolute;
			top: 13%;
			left: 10px;
			opacity: 0.5;
		}
	}
	.smallNav {
		display: none;
	}
	.notRead {
		font-weight: 700;
	}
}
.dummyIcon {
	height: 40px;
	width: 40px;
	object-fit: cover;
	cursor: pointer;
	border-radius: 50%;
}
@media only screen and (max-width: 945px) {
	.navbar-dark {
		.smallNav {
			display: flex;
			justify-content: space-between;
			padding: 20px;
			padding-left: 0;
			.rightSide {
				display: flex;
				justify-content: space-between;
				gap: 20px;
				align-items: center;
				.notificationsIcon {
					color: $dark-blue;
					background-color: $light-blue;
					padding: 7px;
					border-radius: 10px;
					box-sizing: content-box;
					cursor: pointer;
					transition: 0.3s;
					&:hover {
						background-color: $dark-blue;
						color: $light-blue;
					}
				}
			}
		}
		.bigNav {
			display: none;
		}
		.mobileMenu {
			display: block;
			visibility: visible;
			transition: 0.5s;
			ul {
				display: block;
				text-align: end;
				li {
					padding-bottom: 20px;
					margin: 0;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					text-align: right;
					color: $white;
					p,
					h6 {
						margin: 10px 0;
						padding: 10px 20px;
						padding-right: 20px;
						border-right: 3px solid $whiteless;
						line-height: 1;
						cursor: pointer;
						&:hover {
							border-right: 3px solid $light-blue;
						}
					}
					.jobs {
						text-decoration: none;
						padding: 6px 18px;
						border-radius: 3px;
						border: 1px solid $white;
						transition: 0.3s;
						margin-right: 5px;
						min-width: 85px;
						text-align: center;
						&:hover {
							background-color: $white;
							color: $dark-blue;
						}
					}
					img {
						margin: 0;
						display: none;
					}
				}
			}
		}
		ul {
			.inline {
				margin: auto 0;
				vertical-align: middle;

				.jobs {
					text-decoration: none;
					border-radius: 3px;
					border: 1px solid $whiteless;
					transition: 0.3s;
					color: $white;
					&:hover {
						background-color: $white;
						color: $dark-blue;
					}
				}
				img {
					margin-left: 5px;
					height: 40px;
				}
			}
			.inline2 {
				.logo {
					display: inline-block;
					img {
						margin-right: 7px;
						width: 40px;
					}
					span {
						font-size: 24px;
						font-weight: 600;
						color: $dark-blue;
						vertical-align: middle;
						margin: auto 0;
					}
				}
			}
		}
	}
}
