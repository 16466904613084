.applyBackgroundPop {
	display: flex;
	justify-content: center;
	background-color: #09406735;
	width: 100vw;
	position: absolute;
	top: 0;
	height: var(--doc-height);
	z-index: 100;

	.popupLoginMessage {
		// create css for a popup message centered on the screen

		position: fixed;
		top: 20%;
		width: 450px;
		max-width: 90vw;
		background-color: #fffffe;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
		padding: 40px;
		border-radius: 5px;

		.header {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			.closeButton {
				position: absolute;
				top: 10px;
				right: 10px;
				font-size: 24px;
				text-rendering: optimizeLegibility;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				cursor: pointer;
				// the redish color from the button
				color: #ef4565;
				&:hover {
					color: #ff0000;
				}
			}
		}
		textarea {
			display: block;
			width: 100%;
			margin-bottom: 10px;
			padding: 10px;
			min-height: 80px;
		}
	}
}
