@import 'colorVariables.scss';

.ant-select,
.ant-picker {
	min-height: 40px;
}

.ant-select-selector {
	text-align: left !important;
	min-height: 40px !important;
}

// .ant-select-selection {
// 	background-color: $light-blue;
// }

.ant-select-selection-overflow {
	padding: 0px !important;
}

.ant-select-selection-item {
	color: $dark-charcoal;
}

.ant-select-multiple .ant-select-selection-item {
	min-height: 30px;
	display: flex !important;
	align-items: center !important;
}

.ant-select-selection-item-content {
	text-align: center;
}

.ant-select-selection-item-remove {
	margin: 0;
	font-size: 0.9em !important;
}

// .ant-select-item-option-selected {
// 	background-color: $light-blue !important;
// 	color: $dark-blue !important;
// }

.ant-select-item-option-content {
	text-align: left !important;
	// color: $charcoal;
}

.ant-table {
	font-size: 1.05em;
	color: $dark-charcoal !important;
}

.ant-table-cell {
	overflow-wrap: normal !important;
}

.ant-tag {
	text-align: center !important;
	padding: 5px 10px !important;
}

.ant-pagination-item {
	border-color: $light-blue !important;
}

.ant-input {
	min-height: 40px;
}

.ant-input-affix-wrapper {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	// padding-left: 0 !important;
}

.ant-input-search-button {
	height: 40px !important;
}

// make the focus background transparent for the ant input
.ant-input:focus {
	background-color: transparent !important;
}
// make the focus background transparent for the ant input auto fill
.ant-input-affix-wrapper {
	background-color: $white !important;
	&:focus {
		background-color: transparent !important;
	}
}

.ant-segmented {
	border-radius: 7px !important;
}

.ant-segmented-item-label {
	border-radius: 5px !important;
	font-size: 1em !important;
}

.ant-dropdown-menu-item {
	text-align: left;
}

.ant-drawer-title {
	text-align: left;
}

// .ant-notification-notice-message {
// 	padding-left: 0px !important;
// 	margin-left: 0px !important;
// }

// .anticon-info-circle {
// 	display: none !important;
// }
