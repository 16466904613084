.hiring {
	h1 {
		padding: 50px 0;
		font-weight: 600;
	}
	.section2 {
		padding: 80px 0;
		background-color: #d8eefe;
		h2 {
			font-size: calc(30px + 1.3vw);
			font-weight: 500;
		}
		.outerBox {
			margin: 0 auto;
			margin-top: 50px;
			.innerBox {
				display: inline-block;
				vertical-align: top;
				width: 400px;
				max-width: 100vw;
				padding: 20px;
				.icon {
					font-size: 48px;
					color: #ef4565;
				}
				h3 {
					font-size: 18px;
					height: 40px;
					margin: 15px;
				}
				.circle {
					margin-bottom: 20px;
					h2 {
						display: block;
						border-radius: 50%;
						margin: 15px auto;
						padding: 20px;
						width: 95px;
						font-size: 38px;
						border: 4px #ef4565 solid;
					}
				}
				img {
					height: 60px;
					width: 60px;
					margin-bottom: 10px;
				}
			}
		}
	}

	.pricingContainerEmployees {
		width: 100%;
		display: inline-block;
		margin: 0 auto;
		background-color: #fffffe;
		padding: 40px 0;
		h2 {
			font-weight: 600;
		}

		h3 {
			margin-bottom: 20px;
		}
		.bigContainer {
			display: inline-block;
			vertical-align: top;
			width: 350px;
			height: 350px;
			padding: 25px;
			margin: 10px;
			background-color: #d8eefe;
			border-radius: 3px;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 0.5px 2px;

			.smallContainer {
				display: flex;

				.iconic {
					font-size: 24px;
				}
				.box {
					width: 100%;
					img {
						width: 100%;
						height: 300px;
					}
					p {
						text-align: left;
						padding-left: 8px;
					}
					ul {
						padding: 0;
						li {
							text-align: left;
						}
					}
				}
			}
		}
	}
}
