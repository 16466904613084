$white: #fffffe;
$whiteless: #fffffe90;
$navy: #094067;
$navyless: #09406790;
$dark-blue: #003366;
$blue: #3da9fc;
$light-blue: #d8eefe;
$light-blueless: #d8eefecd;
$grey: #5f6c7b;
$greyless: #5f6c7b50;
$red: #ef4565;
$background-grey: #f5f5f5;
$charcoal: #3e4149;
$link-blue: #0077b6;

.eor-container {
	h1 {
		font-size: 2.5em;
		color: $white;
	}
	h2 {
		font-size: 1.8em;
		font-weight: 700;
	}
	h3 {
		font-size: 1.1em;
	}

	.eor-first {
		min-height: var(--doc-height);
		background-color: $dark-blue;
		text-align: left;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.eor-first-content {
			flex: 1;
			width: 1200px;
			max-width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 100px;
			padding: 20px;

			.left-side {
				display: flex;
				flex-direction: column;
				gap: 15px;
				width: 100%;
				max-width: 650px;
				h1 {
					font-weight: 700;
					width: 100%;
					max-width: 500px;
				}
				h3 {
					color: $light-blueless;
					width: 100%;

					font-weight: 400;
					line-height: 1.5;
				}
				.buttonLightBlue {
					width: fit-content;
					padding: 10px 24px;
				}
			}
			.right-side {
				img {
					width: 400px;
					max-width: 100%;
					object-fit: contain;
				}
			}
		}
	}

	.eor-second {
		padding: 20px;
		width: 1100px;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 100px auto;
		text-align: left;
		gap: 100px;

		.first-segment {
			display: flex;
			flex-direction: column;
			gap: 15px;
			h2 {
				max-width: 700px;
			}
		}
		.second-segment {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 80px;
			.left-side {
				display: flex;
				flex-direction: column;
				gap: 15px;
				max-width: 700px;

				h2 {
					max-width: 450px;
					font-weight: 600;
				}
			}
			img {
				height: 220px;
				object-fit: contain;
			}
		}
		.third-segment {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 0 auto;
			gap: 15px;
			h4 {
				text-align: center;
				color: $navy;
				font-weight: 500;
				max-width: 600px;
				margin: 0 auto;
				margin-bottom: 20px;
			}
			.third-segment-cards {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				gap: 20px;
				.segment-card {
					display: flex;
					align-items: center;
					flex-direction: row;
					min-width: 320px;
					flex: 1;
					gap: 15px;
					padding: 20px;
					box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
					border-radius: 10px;
					.icon {
						box-sizing: content-box;
						padding: 15px;
						color: $navy;
						background-color: $light-blue;
						border-radius: 10px;
					}
					p {
						margin: 0;
					}
				}
			}
		}
	}
	.eor-third {
		background-color: $background-grey;
		padding: 80px 20px;
		text-align: left;

		.eor-third-content {
			max-width: 1200px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			gap: 20px;
			.questions {
				.question {
					background-color: $white;
					max-width: 100%;
					height: fit-content;
					border-radius: 10px;

					.header {
						font-weight: 600;
					}
					.body {
						font-weight: 400px;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.eor-container {
		h1 {
			font-size: 2em;
		}
		h2 {
			font-size: 1.6em;
		}
		h3 {
			font-size: 1.1em;
		}
	}
}

/* Mobile devices */
@media (max-width: 480px) {
	.eor-container {
		h1 {
			font-size: 1.8em;
		}
		h2 {
			font-size: 1.4em;
		}
		h3 {
			font-size: 1.1em;
		}
	}
}
