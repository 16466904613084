@import '../../colorVariables.scss';
.height-setter {
	min-height: var(--doc-height);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.contact-us {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 10px;
		h1 {
			font-weight: 500;
			color: $dark-charcoal;
			font-family: 'Plus Jakarta Sans', sans-serif;
			font-size: 2em;
		}
	}
}
