@import '../../colorVariables.scss';

$is-dark-mode: false;
$text-color: if($is-dark-mode, $white, $dark-blue);

.nav2 {
	padding: 12px 24px;
	z-index: 1000;

	.ant-collapse-header {
		padding: 0 6px !important;
	}
	ul {
		display: flex;
		justify-content: space-between;
		list-style: none;
		align-items: center;
		margin-bottom: 0;
		padding: 0;
		.notificationsIcon {
			color: $deep-blue;
			background-color: $light-blue;
			padding: 7px;
			margin-left: 10px;
			border-radius: 10px;
			box-sizing: content-box;
			cursor: pointer;
			transition: 0.3s;
			&:hover {
				background-color: $deep-blue;
				color: $white;
			}
		}
		.inline {
			display: flex;
			justify-content: space-between;
			margin: auto 0;
			vertical-align: middle;
			gap: 30px;
			.jobs {
				text-decoration: none;
				padding: 6px 18px;
				border-radius: 3px;
				border: 1px solid $navyless;
				transition: 0.3s;
				margin-right: 5px;
				margin-left: 20px;
				color: $text-color;
				&:hover {
					background-color: $deep-blue;
					color: $white;
				}
			}

			.right-links {
				display: flex;
				align-items: center;
				gap: 10px;
			}
			.hidden {
				display: none;
			}
			.visible {
				display: flex;
				flex-direction: column;
				position: absolute;
				z-index: 2;
				background-color: $white;
				margin-left: -220px;
				margin-top: 15px;
				border-radius: 5px;
				box-shadow:
					rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
					rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
				width: 270px;
				a {
					text-decoration: none;
					z-index: 2;
					color: $text-color;

					&:hover {
						background-color: $deep-blue;
						color: $white;
					}
				}
				p {
					cursor: pointer;
					z-index: 2;
					overflow-x: hidden;
					background-color: $white;
					color: $dark-charcoal;
					text-decoration: none;
					border-radius: 3px;
					padding: 6px;
					margin: 0;
					&:hover {
						background-color: $link-blue;
						color: $white;
					}
				}
				h6 {
					overflow-x: hidden;
					text-overflow: ellipsis;
					z-index: 2;
					font-size: calc(16px);
					margin: 0;
					padding: 2px;
					text-align: left;
				}
				.pointer {
					display: flex;
					position: absolute;
					top: -15px;
					right: 10px;
					z-index: 0.9;
					height: 40px;
					width: 40px;
					background: $white;
					margin: 0 auto;
					transform: rotate(45deg);
					border-radius: 0 0 3px 0;
					margin-top: 12px;
					margin-left: 6.5px;
				}
			}
		}
		.inline2 {
			display: flex;
			justify-content: space-between;
		}
		li {
			margin: auto 0;
			vertical-align: middle;

			a {
				text-decoration: none;
				color: $text-color;
				&:hover {
					color: $link-blue;
				}
			}

			button {
				padding: 4px 14px;
				vertical-align: middle;
				border-radius: 3px;
			}
			.hamburger {
				background-color: $deep-blue;
				color: $white;
			}
		}
	}
	.menu {
		display: none;
		visibility: collapse;
		.jobs {
			text-decoration: none;
			padding: 6px 18px;
			border-radius: 3px;
			border: 1px solid $navyless;
			transition: 0.3s;
			margin-right: 5px;
			color: $text-color;
			&:hover {
				background-color: $deep-blue;
				color: $white;
			}
		}
	}
	.searchList {
		position: relative;
		input {
			border: 1px solid $navyless;
			padding: 5px;
			padding-right: 20px;
			padding-left: 30px;
		}
		.searchy {
			position: absolute;
			top: 13%;
			left: 10px;
			opacity: 0.5;
		}
	}
	.smallNav {
		display: none;
	}
	.notRead {
		font-weight: 700;
	}
}
.dummyIcon {
	height: 34px;
	width: 34px;
	object-fit: cover;
	cursor: pointer;
	border-radius: 50%;
}
@media only screen and (max-width: 930px) {
	.nav2 {
		padding: 6px 8px;
		.smallNav {
			display: flex;
			justify-content: space-between;
			padding-left: 0;
			.rightSide {
				display: flex;
				justify-content: space-between;
				gap: 20px;
				align-items: center;
				.notificationsIcon {
					color: $deep-blue;
					background-color: $light-blue;
					padding: 7px;
					border-radius: 10px;
					box-sizing: content-box;
					cursor: pointer;
					transition: 0.3s;
					&:hover {
						background-color: $deep-blue;
						color: $white;
					}
				}
			}
		}
		.bigNav {
			display: none;
		}
		.mobileMenu {
			visibility: visible;
			transition: 0.5s;
			ul {
				display: block;
				text-align: end;
				li {
					padding-bottom: 20px;
					margin: 0;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					text-align: right;

					p,
					h6 {
						margin: 10px 0;
						padding: 10px 20px;
						padding-right: 10px;
						border-right: 3px solid $navyless;
						line-height: 1;
						cursor: pointer;
					}
					.jobs {
						text-decoration: none;
						padding: 6px 18px;
						border-radius: 3px;
						border: 1px solid $navyless;
						transition: 0.3s;
						margin-right: 5px;
						min-width: 85px;
						text-align: center;
						color: $text-color;
						&:hover {
							background-color: $deep-blue;
							color: $white;
						}
					}
					img {
						margin: 0;
						display: none;
					}
				}
			}
		}
		ul {
			.inline {
				margin: auto 0;
				vertical-align: middle;
				.jobs {
					text-decoration: none;
					border-radius: 3px;
					border: 1px solid $navyless;
					transition: 0.3s;
					color: $text-color;
					&:hover {
						background-color: $deep-blue;
						color: $white;
					}
				}
				img {
					margin-left: 5px;
					height: 40px;
				}
			}
			.inline2 {
				.logo {
					display: inline-block;
					img {
						margin-right: 7px;
						width: 40px;
					}
					span {
						font-size: 24px;
						font-weight: 600;
						color: $deep-blue;
						vertical-align: middle;
						margin: auto 0;
					}
				}
			}
		}
	}
}
