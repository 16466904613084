@import '../../colorVariables.scss';

.edit-profile-container {
	display: flex;
	flex-direction: column;
	min-height: var(--doc-height);
	background-color: $background-grey;
	.edit-profile-outer {
		width: 1160px;
		max-width: 100%;
		margin: 20px auto;
		padding: 10px;

		h1 {
			color: $dark-charcoal;
		}
	}
	.editProfileContainer {
		border-radius: 5px;
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		.left-side {
			width: 250px;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		.right-side {
			width: 650px;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		.container {
			display: flex;
			flex-direction: column;
			padding: 20px;
			box-shadow:
				rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
				rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
			background-color: $white;
			border-radius: 5px;
			max-width: 100%;

			.edit-container,
			.edit-container form {
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 5px;
				max-width: 100%;

				input,
				textarea,
				select,
				option {
					width: 100%;
				}

				.buttons {
					display: flex;
					gap: 6px;
					width: fit-content;
					max-width: 150px;

					.edit-icon {
						font-size: 24px;
						cursor: pointer;
						padding: 6px;
						border-radius: 5px;
						box-sizing: content-box;
						background-color: $background-grey;

						&:hover {
							color: $deep-blue;
						}
					}
				}
			}
			.workExperience {
				padding: 10px 0;
				.buttons {
					display: flex;
					gap: 6px;
					width: fit-content;
					max-width: 150px;

					.edit-icon {
						background-color: $background-grey;
						padding: 6px;
						border-radius: 5px;
						box-sizing: content-box;
						font-size: 24px;
						cursor: pointer;
						&:hover {
							color: $deep-blue;
						}
					}
				}
			}
			h3 {
				color: $dark-charcoal;
				font-weight: 600;
				font-size: 1.2em;
				.plusIcon {
					cursor: pointer;
					transition: 0.3s;
					&:hover {
						color: $grey;
					}
				}
			}
			h4 {
				color: $dark-charcoal;
				font-weight: 500;
				font-size: 1.1em;
			}
			h5 {
				color: $charcoal;
				font-weight: 400;
				font-size: 1em;
			}

			.skill {
				p {
					padding: 4px 8px;
					margin: 0;
					font-weight: 600;
				}
				.icon {
					font-size: 22px;
					vertical-align: middle;
					margin-bottom: 2px;
					cursor: pointer;
					margin-left: 5px;
					&:hover {
						color: #ef4565;
					}
				}
				display: inline-block;
				border: 1.5px solid $light-blue;
				background-color: $light-blue;
				color: $dark-blue;
				margin-right: 10px;
				margin-bottom: 10px;
				border-radius: 5px;
			}
		}

		.circle-image {
			width: 80px;
			height: 80px;
			border-radius: 5px;
			object-fit: cover;
			cursor: pointer;
			transition: 0.3s;
			margin-bottom: 10px;
			box-shadow:
				rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
				rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}
