.notifications {
	.list {
		width: 800px !important;
		max-width: 100%;
		padding-top: 0;
		margin-top: 0;

		.listItem {
			background-color: #fffffe;
			border-radius: 5px;
			&:hover {
				background-color: #d8eefe;
				color: #094067;
			}
		}
	}
}
