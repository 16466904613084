$white: #fffffe;
$whiteless: #fffffe90;
$navy: #094067;
$navyless: #09406790;
$dark-blue: #003366;
$blue: #3da9fc;
$light-blue: #d8eefe;
$light-blueless: #d8eefecd;
$light-blueless2: #d8eefc50;
$grey: #5f6c7b;
$greyless: #5f6c7b80;
$red: #ef4565;
$background-grey: #f5f5f5;
$background-greyless: #f5f5f560;
$charcoal: #3e4149;
$charcoaless: #3e414990;
$link-blue: #0077b6;
$deep-blue: #082e6e;
$dark-charcoal: #2b2d33;
$background-light-cream: #fafaf6;
$background-grey-blue: #f8fafc;
