@import '../../colorVariables.scss';

.fullLogo {
	display: inline-block;
	.fullLogoImg {
		margin-right: 7px;
		width: 30px;
	}
	span {
		font-size: 24px;
		font-weight: 600;
		color: $dark-blue;
		vertical-align: middle;
		margin: auto 0;
	}
}

.fullLogoSmall {
	display: inline-block;
	.fullLogoImg {
		margin-right: 7px;
		width: 25px;
	}
	span {
		font-size: 1.3em;
		color: $deep-blue;
		vertical-align: middle;
		margin: auto 0;
	}
}

.lightFullLogoSmall {
	display: inline-block;
	.fullLogoImg {
		margin-right: 7px;
		width: 22px;
	}
	span {
		font-size: 1.2em;
		font-weight: 600;
		color: #fffffe;
		vertical-align: middle;
		margin: auto 0;
	}
}

.lightLogo {
	display: inline-block;
	.lightLogoImg {
		margin-right: 7px;
		width: 30px;
	}
	span {
		font-size: 24px;
		font-weight: 600;
		color: #fffffe;
		vertical-align: middle;
		margin: auto 0;
	}
}
.lightLogoLarger {
	display: inline-block;
	.lightLogoLargerImg {
		margin-right: 12px;
		width: 45px;
	}
	span {
		font-size: 36px;
		font-weight: 600;
		color: #fffffe;
		vertical-align: middle;
		margin: auto 0;
	}
}
