$white: #fffffe;
$whiteless: #fffffe90;
$navy: #094067;
$navyless: #09406790;
$dark-blue: #003366;
$blue: #3da9fc;
$light-blue: #d8eefe;
$light-blueless: #d8eefecd;
$grey: #5f6c7b;
$red: #ef4565;
$background-grey: #f5f5f5;
$charcoal: #3e4149;
$link-blue: #0077b6;

.homeContainer {
	h2 {
		font-weight: 600;
	}
	h4 {
		font-size: 1.2em;
		font-weight: 500;
		color: $dark-blue;
	}
	h3 {
		color: $dark-blue;
	}
	p {
		color: $charcoal;
		font-weight: 400;
	}
	.section1 {
		background-image: url('../../../public/blueBackground.svg');
		background-size: cover;
		min-height: var(--doc-height);
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.full-container {
			flex: 1;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 20px;

			.left {
				flex: 1;
				margin-top: -10vw;
				text-align: left;
				max-width: 900px;
				display: flex;
				flex-direction: column;
				gap: 15px;

				h1 {
					font-size: 3.5em;
					color: #fffffe;
				}
				h2 {
					color: #fffffe;
					font-weight: 600;
				}
				.buttons {
					display: flex;
					flex-wrap: wrap;
					gap: 20px;
				}

				p {
					color: #fffffe;
					font-size: 1.1em;
					opacity: 0.9;
				}
			}
			.right {
				margin-top: -10vw;

				img {
					height: 650px;
					width: 500px;
					object-fit: cover;
				}
			}
		}
	}
	.section2 {
		.carousel {
			padding: 60px 20px;
			.inner-banner {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				gap: 40px;

				.inner-flex {
					.inner-queue {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-wrap: wrap;

						gap: 50px;

						img {
							box-sizing: content-box;
							height: 80px;
							width: 120px;
							object-fit: contain;
							border-radius: 10px;
							padding: 20px;
							background-color: $white;
							box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px,
								rgba(240, 46, 170, 0.05) 25px 25px;
							transition: 0.5s;
							&:hover {
								box-shadow: rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px,
									rgba(240, 46, 170, 0.05) 0px 25px;
							}
						}
					}
				}
			}
		}
	}
	.section3 {
		padding: 180px 20px;
		text-align: left;

		.section3-container {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;
			gap: 40px;
			max-width: 1100px;
			margin: 0 auto;

			.left-side {
				max-width: 100%;
				min-width: 300px;

				flex: 7;
				h2 {
					font-weight: 600;
				}
			}
			.right-side {
				display: flex;
				flex-wrap: wrap;

				gap: 20px;
				flex: 5;

				min-width: 300px;
				max-width: 100%;

				.facilitate-card {
					flex: 1;
					display: flex;
					align-items: center;
					gap: 15px;
					padding: 10px;
					border-radius: 10px;
					background-color: $white;
					transition: 0.3s;
					box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
					min-width: 300px;
					&:hover {
						transform: scale(1.06);
					}

					p {
						margin: 0;
						color: $dark-blue;
					}
					.icon {
						box-sizing: content-box;
						padding: 15px;
						border-radius: 10px;
						background-color: $light-blue;
						color: $dark-blue;
					}
				}
			}
		}
	}
	.section4 {
		padding: 100px 20px;
		text-align: center;
		.section4-container {
			max-width: 1300px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			gap: 15px;
			h2 {
				font-weight: 700;
			}

			.candidate-cards {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				gap: 20px;

				.candidate-card {
					box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
					min-width: 300px;
					max-width: 310px;
					border-radius: 10px;
					min-height: 270px;
					max-height: 270px;
					display: flex;
					justify-content: space-between;
					img {
						border-radius: 10px 0px 0 10px;
						height: 100%;
						width: 42%;
						object-fit: cover;
					}
					.content {
						width: 100%;
						text-align: left;
						padding: 20px;
						display: flex;
						flex-direction: column;
						gap: 5px;
						.name {
							font-size: 1em;
							color: $dark-blue;
							font-weight: 600;
							padding: 5px;
							background-color: $light-blue;
							border-radius: 5px;
							text-align: center;
						}
						.role {
							display: flex;
							gap: 5px;
							align-items: flex-start;
						}
					}
				}
			}
		}
	}

	.section5 {
		background-color: $background-grey;
		padding: 100px 20px;
		.section5-container {
			width: 1100px;
			max-width: 100%;
			margin: 0 auto;
			.steps {
				margin-top: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				gap: 20px;
				text-align: left;
				width: 100%;
				.step {
					display: flex;
					flex-direction: column;
					border-radius: 10px;
					padding: 20px;
					box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
					width: 400px;
					background-color: $white;
					.step-header {
						display: flex;
						flex-direction: row-reverse;
						align-items: top;
						text-align: left;
						gap: 20px;
						justify-content: space-between;

						h3 {
							margin: 0;
						}

						.step-icon {
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 18px;
							color: $dark-blue;
							background-color: $light-blue;
							padding: 10px 18px;
							border-radius: 10px;
							height: 50px;
							min-width: 50px;
							max-width: 50px;
						}
					}
				}
			}
		}
	}

	.section6 {
		padding: 100px 20px;
		.section6-container {
			max-width: 900px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			gap: 15px;
			text-align: left;
			.header {
				display: flex;
				justify-content: space-between;
				gap: 20px;
				flex-wrap: wrap;
				align-items: center;
			}
			.tick {
				color: $grey;
				opacity: 0.5;
			}
			.tick-true {
				color: $dark-blue;
			}
		}
	}

	.section7 {
		padding: 100px 20px;
		p,
		h1,
		h2,
		h3,
		h4,
		h5 {
			margin: 0;
		}
		.section7-container {
			max-width: 1100px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			gap: 40px;
			h2 {
				font-weight: 700;
			}
			.client-cards {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				text-align: left;
				gap: 20px;

				.client-card {
					box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
					width: 350px;
					border-radius: 10px;

					img {
						width: 100%;
						height: 300px;
						object-fit: cover;
						border-radius: 10px 10px 0 0;
					}

					.content {
						display: flex;
						flex-direction: column;
						gap: 10px;
						h3 {
							font-weight: 600;
						}
						.stars {
							color: goldenrod;
							font-size: 18px;
						}
						padding: 20px;
						.italic {
							font-style: italic;
						}
					}
				}
			}
		}
	}
	.section8 {
		display: flex;
		justify-content: center;
		padding: 100px 20px;
		background-color: $background-grey;
		.section8-container {
			width: 1100px;
			max-width: 100%;
			text-align: left;
			display: flex;
			flex-direction: column;
			gap: 15px;
		}
	}
	.section9 {
		padding: 100px 20px;
		.section9-container {
			h2 {
				color: $white;
			}
			color: $white;
			padding: 40px;
			margin: 0 auto;
			background-color: $navy;
			width: 600px;
			max-width: 100%;

			border-radius: 10px;
			display: flex;
			flex-direction: column;
			gap: 15px;
			box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.homeContainer {
		h4 {
			font-size: 1.1em;
		}
	}
}

@media only screen and (max-width: 800px) {
	.homeContainer {
		.section1 {
			.full-container {
				align-items: flex-start;
				padding: 40px;
				margin: 0 auto;
				.left {
					margin: 0 auto;
					h1 {
						font-size: 2em;
					}
					h2 {
						font-size: 1.3em;
					}
					h3 {
						font-size: 1.1em;
					}
				}
			}
		}
		.section2 {
			.outerBox {
			}
		}
	}
}
