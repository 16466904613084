.blog-page {
	width: 100%;
	max-width: 100%;
	text-align: left;

	.blog-post {
		width: 800px;
		max-width: 100%;
		margin: 0 auto;
		padding: 20px;

		.bread-crumbs {
			margin: 20px 0 80px 0;
		}

		.blog-post-title {
			font-size: 2.5em;
			font-weight: 700;
			margin: 50px 0 20px 0;
		}
		.blog-post-description {
			font-size: 1.5em;
			font-weight: 400;
			margin: 20px 0 20px 0;
		}
		.blog-post-author {
			font-size: 1em;
			font-weight: 400;
			margin: 20px 0 20px 0;
			font-style: italic;
		}
		.blog-post-image {
			width: 100%;
			height: 300px;
			object-fit: cover;
			margin: 20px 0 20px 0;
		}

		.blog-post-content {
			h2 {
				font-size: 1.7em;
			}
			img {
				width: 100%;
				object-fit: cover;
				margin: 20px 0 20px 0;
			}
		}

		.blog-post-button {
			margin: 20px 0;
			font-weight: 600;
		}
		.blog-post-disclaimer {
			font-size: 1em;
			font-style: italic;
			margin: 20px 0;
		}
	}
}

@media (max-width: 1200px) {
	.blog-page {
		.blog-post {
			.bread-crumbs {
				margin-bottom: 50px;
			}
			.blog-post-title {
				font-size: 2.1em;
			}
			.blog-post-image {
				height: 200px;
			}
			.blog-post-description {
				font-size: 1.4em;
			}
			.blog-post-author {
				font-size: 1em;
			}
			.blog-post-content {
				h2 {
					font-size: 1.5em;
				}
				img {
					width: 100%;
					object-fit: cover;
					margin: 20px 0 20px 0;
				}
			}
		}
	}
}

/* Tablet devices */
@media (max-width: 768px) {
	.blog-page {
		.blog-post {
			.bread-crumbs {
				margin-bottom: 50px;
			}
			.blog-post-title {
				font-size: 2em;
			}
			.blog-post-image {
				height: 200px;
			}
			.blog-post-description {
				font-size: 1.3em;
			}
			.blog-post-author {
				font-size: 0.9em;
			}
			.blog-post-content {
				h2 {
					font-size: 1.5em;
				}
				img {
					width: 100%;
					object-fit: cover;
					margin: 20px 0 20px 0;
				}
			}
		}
	}
}

/* Mobile devices */
@media (max-width: 480px) {
	.blog-page {
		.blog-post {
			.bread-crumbs {
				margin-bottom: 30px;
			}
			.blog-post-title {
				font-size: 1.5em;
			}
			.blog-post-description {
				font-size: 1.1em;
			}
			.blog-post-author {
				font-size: 0.8em;
			}
			.blog-post-content {
				h2 {
					font-size: 1.3em;
				}
			}
		}
	}
}
