.emailRequest {
	margin: 50px auto;
	text-align: center;
	width: 500px;
	max-width: 95vw;
	padding: 60px 20px;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	border-radius: 5px;
	background-color: #fffffe;
	text-align: center;
	h1 {
		font-size: 2rem;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		input {
			width: 350px;
			max-width: 95%;
			padding: 10px;
			border: 1px solid #094067;
			border-radius: 3px;
			margin: 10px 0;
			transition: 0.3s;
			&:focus {
				outline: none;
				border: 1px solid #094067;
				box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
			}
		}
	}
}

.resetPassword {
	margin: 50px auto;
	text-align: center;
	width: 500px;
	max-width: 95vw;
	padding: 60px 20px;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	border-radius: 5px;
	background-color: #fffffe;
	text-align: center;
	h1 {
		font-size: 2rem;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		input {
			width: 350px;
			max-width: 95%;
			padding: 10px;
			border: 1px solid #094067;
			border-radius: 3px;
			margin: 10px 0;
			transition: 0.3s;
			&:focus {
				outline: none;
				border: 1px solid #094067;
				box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
			}
		}
	}
}
