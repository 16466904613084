@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
	font-family: 'Satoshi Variable';
	src: url('../public/fonts/Satoshi-Variable.ttf') format('truetype-variations');
	font-weight: 100, 200, 300, 400, 500, 600, 700, 800, 900; /* Adjust as needed */
	font-style: normal;
}

*,
*::after,
*::before {
	box-sizing: border-box;
	// font-family: 'Satoshi Variable', sans-serif !important;
	font-family: 'Plus Jakarta Sans', sans-serif !important;
}

html {
	scroll-behavior: smooth;
}

$white: #fffffe;
$whiteless: #fffffe90;
$navy: #094067;
$navyless: #09406790;
$dark-blue: #003366;
$blue: #3da9fc;
$light-blue: #d8eefe;
$light-blueless: #d8eefecd;
$grey: #5f6c7b;
$red: #ef4565;
$background-grey: #f5f5f5;
$charcoal: #3e4149;
$link-blue: #0077b6;

body {
	background-color: $white;
	scroll-behavior: smooth;
	margin: 0 auto;
	color: #5f6c7b;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
	font-weight: 600;
	font-size: 2rem;
}
h2 {
	font-size: 1.75rem;
}
h3 {
	font-size: 1.5rem;
}
p {
	font-size: 1rem;
}

h1,
h2,
h3,
hr {
	color: #094067;
}

// button {
// 	font-family: 'Plus Jakarta Sans', sans-serif;
// 	letter-spacing: 0.2px;
// 	font-weight: 600;
// 	box-sizing: border-box;
// 	background-color: #3da9fc;
// 	padding: 6px 24px;
// 	border: 1px solid #3da9fc;
// 	border-radius: 5px;
// 	color: #fffffe;
// 	transition: 0.3s;
// 	flex-shrink: 0;
// }

.css-k008qs {
	display: -webkit-box !important;
}
