@import '../../colorVariables.scss';

.how-it-works-container {
	width: 1200px;
	max-width: 100%;
	padding: 20px;
	margin: 80px auto;
	margin-top: 40px;
	text-align: left;
	h1 {
		margin-bottom: 60px;
	}
	.first-segment {
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		width: 100%;
		gap: 80px;
		margin-bottom: 140px;
		.first-segment-content {
			p {
				font-weight: 400;
			}
			.how {
				font-weight: 500;
			}
			.segment-part {
				display: flex;
				gap: 20px;
				margin-bottom: 10px;
				.icon {
					box-sizing: content-box;
					padding: 15px;
					background-color: $background-grey-blue;
					color: $deep-blue;
					border-radius: 10px;
				}
			}
		}
		.first-segment-image {
			max-width: 550px;
			object-fit: contain;
			border-radius: 10px;
		}
	}

	.second-segment {
		display: flex;
		justify-content: center;
		width: 100%;
		gap: 80px;
		.first-segment-content {
			p {
				font-weight: 400;
			}
			.how {
				font-weight: 500;
			}
			.segment-part {
				display: flex;
				gap: 20px;
				margin-bottom: 10px;
				.icon {
					box-sizing: content-box;
					padding: 15px;
					background-color: #d8eefe;
					border-radius: 10px;
					color: #094067;
				}
				.segment-part-text {
					h3 {
						font-size: 1.4em;
					}
				}
			}
			a {
				margin-top: 20px;
				display: flex;
				align-items: center;
				gap: 10px;
				width: fit-content;
				color: #3da9fc;
				&:hover {
					text-decoration: underline;
				}
				.arrow {
					font-size: 18px;
					color: #5f6c7b;
				}
			}
		}
		.first-segment-image {
			width: 550px;
			border-radius: 10px;
		}
	}
}

// media screen < 800px
@media only screen and (max-width: 768px) {
	.how-it-works-container {
		margin-bottom: 60px;

		.first-segment,
		.second-segment {
			.first-segment-content {
				.segment-part {
					.segment-part-text {
						h3 {
							font-size: 1.2em;
						}
					}
				}
			}
		}
	}
}
