@import '/src/colorVariables.scss';

.landingPageContainer {
	display: flex;
	flex-direction: column;
	min-height: var(--doc-height);
}

.landingPage {
	text-align: left;
	margin: 0 auto;
	width: 100%;
	h2 {
		// same color but less opacity
		color: $charcoal;
		font-weight: 500;
	}
	.background {
		background-image: url('../../../public/endless-constellation.svg');
		//blur just the background
	}
	.searchContainer {
		.ant-select-selector {
			height: 50px;
		}
		.searchBar {
			width: 600px;
			max-width: 100%;
			margin: 5px;
			margin-left: 0;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 0.5px 2px;

			// same font as the rest of the page

			&__underline:before {
				border-bottom-color: #09406760;
			}
			&__underline:hover:not(.Mui-disabled):before {
				border-bottom-color: #09406760;
			}
			&__underline:after {
				border-bottom-color: #094067;
			}

			&__focused {
				color: #094067;
			}

			& .MuiOutlinedInput-root {
				& fieldset {
					border-color: #3da9fc20;
				}
				&:hover fieldset {
					border-color: #000000;
				}
				&.Mui-focused fieldset {
					border-color: #000000;
				}
			}
		}
		.searchIcon {
			color: #5f6c7b;
		}
		.buttonRed {
			height: 50px;
			margin: 5px;
			min-width: fit-content;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 0.5px 2px;
		}
	}
	.secondContainer {
		padding: 20px;
		padding-top: 0;
		.title-container {
			display: flex;
			min-width: 300px;
			margin: 5px auto;
			justify-content: center;
			text-align: center;
			max-width: 100%;
			gap: 15px;

			h3 {
				cursor: pointer;
				padding: 15px 20px;
				padding-bottom: 15px;
				margin: 10px 0;
				border-radius: 5px;
				width: 200px;
				font-size: 1em;
				font-weight: 400;
				color: $deep-blue;
			}
			.tab {
				display: flex;
				align-items: center;
				gap: 10px;
				justify-content: center;
				&:hover {
					background-color: $background-grey;
				}
			}
			.selected {
				transition: 0.3s;
				font-weight: 600;
				background-color: $light-blueless;
				&:hover {
					background-color: $light-blueless;
				}
			}
		}
		hr {
			margin: 0;
			padding: 0;
			opacity: 0.1;
		}

		.feedContainer {
			margin: 0 auto;
			text-align: center;
			padding-top: 0;
			.allFeeds {
				// margin-top: 20px;
				// width: 500px;
				// max-width: 100%;
			}

			.cardStyle {
				width: 100%;
				// max-width: 100%;
				text-align: left;
				cursor: pointer;
				transition: 0.3s;
				border: 1px solid #e0e0e0;

				&:hover {
					box-shadow: $link-blue 0px 0px 0px 1px;
				}
			}
			.selectedAllCards {
				box-shadow: $link-blue 0px 0px 0px 1px;
				// box-shadow: $charcoal 0px 0px 0px 1px;
			}
			.feed {
				cursor: pointer;
				padding: 40px;
				background-color: #5f6c7b05;
				// width: 400px;
				text-align: left;
				// max-width: 95%;
				margin: 20px;
				margin-top: 0;
				border-radius: 5px;

				box-shadow:
					rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
					rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
				&:hover {
					background-color: #5f6c7b10;
				}
				h3 {
					font-size: 20px;
				}
			}

			.selectedFeed {
				display: inline-block;
				text-align: left;
				vertical-align: top;
				width: 714px;
				max-width: 100%;
				margin: 20px;
				margin-right: 0;
				border-radius: 5px;
				position: sticky;
				top: 20px;
				margin-top: 0;
				.heading {
					display: flex;
					justify-content: space-between;
					gap: 10px;
					h3 {
						font-size: 20px;
						max-width: 70%;
						cursor: pointer;
						&:hover {
							text-decoration: underline 0.5px;
						}
					}
					.heart {
						color: #094067;
						cursor: pointer;
						font-size: 44px;
						background-color: #5f6c7b20;
						padding: 10px;
						border-radius: 5px;
						min-width: min-content;
						&:hover {
							color: #ef4565;
						}
					}
					.fill {
						color: #ef4565;
					}
				}
			}
			p {
				margin: 0;
			}
		}
	}
}
.modal {
	padding: 20px;

	.modal-body {
		text-align: left;
		.heading {
			display: flex;
			justify-content: space-between;
			align-items: top;
			gap: 10px;
			h3 {
				cursor: pointer;
				&:hover {
					text-decoration: underline 0.5px;
				}
			}
			.heart {
				color: #094067;
				cursor: pointer;
				font-size: 44px;
				background-color: #5f6c7b20;
				padding: 10px;
				border-radius: 5px;
				min-width: 45px;
				flex-grow: 0;

				&:hover {
					color: #ef4565;
				}
			}
			.fill {
				color: #ef4565;
			}
		}
		.selectedFeed {
			display: inline-block !important;
			text-align: left !important;

			vertical-align: top;
			width: 100% !important;
			max-width: 95%;
			border-radius: 5px;
			h5 {
				font-size: 18px;
			}
		}
		.description {
			// max-height: 600px;
			// overflow-y: auto;
			// padding-right: 10px;

			// make the horizontal text overflop wrap around
			word-wrap: break-word;
		}
	}
}
// media queries for less than 800px and button is 100% width

@media only screen and (max-width: 830px) {
	.landingPage {
		.searchContainer {
			.searchBar {
				width: 100%;
				margin: 5px 0;
			}
			.buttonRed {
				width: 100%;
				margin: 5px 0;
			}
		}
	}
}

// @media only screen and (max-width: 1030px) {
// 	.landingPage {
// 		.secondContainer {
// 			.feedContainer {
// 				.allFeeds {
// 					width: 100%;
// 				}
// 			}
// 		}
// 	}
// }
