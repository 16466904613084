.about-us {
	width: 1200px;
	max-width: 100%;
	padding: 20px;
	margin: 0 auto;
	text-align: left;

	p {
		font-weight: 300;
	}
	.first-segment {
		display: flex;
		gap: 40px;
		width: 100%;
		flex-wrap: wrap;
		margin: 50px 0;
		margin-bottom: 150px;

		.left-side {
			width: 500px;
			max-width: 100%;
		}

		img {
			width: 550px;
			max-width: 100%;
			border-radius: 10px;
		}
	}

	.second-segment {
		margin-bottom: 150px;

		.metric-container {
			display: flex;
			gap: 20px;
			justify-content: space-between;
			max-width: 1000px;

			.metric {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				border-left: 2px solid #5f6c7b20;
				padding: 10px 20px;
				p {
					margin: 0;
				}
			}
		}
	}

	.third-segment {
		margin-bottom: 150px;

		.segmentations {
			display: flex;
			flex-wrap: wrap;
			gap: 40px;
			justify-content: space-between;
			.segment-part {
				display: flex;
				gap: 15px;
				flex-direction: column;
				width: 360px;
				max-width: 100%;
				h3 {
					font-size: 1.2em;
					font-weight: 600;
					margin: 0;
				}
				p {
					margin: 0;
				}
				.icon {
					box-sizing: content-box;
					padding: 15px;
					background-color: #d8eefe;
					border-radius: 10px;
					color: #094067;
					font-size: 24px;
					font-weight: 300;
				}
			}
		}
	}

	.fourth-segment {
		margin-bottom: 150px;
		img {
			width: 400px;
			max-width: 100%;
			border-radius: 10px;
			margin-bottom: 10px;
		}
	}
	.fifth-segment {
		margin-bottom: 150px;
		.location-container {
			display: flex;
			gap: 20px;
			.location-card {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding: 20px;
				border: 1px solid #5f6c7b20;
				width: 300px;
				max-width: 100%;
				border-radius: 10px;
				box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
				p {
					margin: 0;
				}
			}
		}
	}
}

.h2-about-us {
	margin-bottom: 20px;
	font-weight: 600;
}

.h2-about-us-less-margin {
	font-weight: 600;
}

.h3-about-us {
	font-size: 1.2em;
	font-weight: 600;
	margin: 0;
}
.h3-about-us-bottom-margin {
	font-size: 1.2em;
	font-weight: 600;
	margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
	.about-us {
		.first-segment {
			flex-direction: column;
			.left-side {
				width: 100%;
			}
			img {
				width: 100%;
			}
		}
		.second-segment {
			.metric-container {
				flex-direction: column;
			}
		}
		.third-segment {
			.segmentations {
				.segment-part {
					width: 100%;
				}
			}
		}
		.fourth-segment {
			img {
				width: 100%;
			}
		}
		.fifth-segment {
			.location-container {
				flex-direction: column;
				.location-card {
					width: 100%;
				}
			}
		}
	}
}
